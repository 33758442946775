import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/homepage/Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useInView } from "react-intersection-observer";
import { motion, useMotionValue } from "framer-motion";
import "react-lazy-load-image-component/src/effects/blur.css";

import {
  Edit_Single_Text,
  Edit_Image,
} from "../../functions/ReuseableFunctions";

export default function VividSection(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1100px)",
  });

  const [ref1, inView1] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref2, inView2] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref3, inView3] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref4, inView4] = useInView({
    threshold: 0,
    triggerOnce: false,
  });
  const [ref5, inView5] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref6, inView6] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref7, inView7] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref8, inView8] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref9, inView9] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref10, inView10] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref11, inView11] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const ani_mate1 = {
    visible: { opacity: 1 },
    hidden: {
      opacity: 0,
      scale: 1,
      x: 150,
    },
  };

  const ani_mate2 = {
    visible: { opacity: 1 },
    hidden: {
      opacity: 0,
      scale: 1,
      y: 50,
    },
  };

  const ani_mate3 = {
    visible: { opacity: 1 },
    hidden: {
      opacity: 0,
      scale: 1,
      x: 150,
    },
  };

  const ani_mate4 = {
    visible: { opacity: 1 },
    hidden: {
      opacity: 0,
      y: 100,
    },
  };

  const [refimg, inViewimg] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [refcp, inViewcp] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  return (
    <>
      <div>
        <div class="container vividCover">
          <div class="vividtitlesection mt-4">
            <motion.div
              animate={!isDesktopOrLaptop || inView ? "visible" : "hidden"}
              variants={ani_mate2}
              transition={{ duration: 0.6 }}
              ref={ref}
              class="vividtitle mb-3"
              id="Vividhp"
            >
              {Edit_Single_Text(
                props.VividSection.title,
                "VividSection.title",
                props.id,
                props.activate
              )}
            </motion.div>

            <motion.div
              animate={!isDesktopOrLaptop || inView ? "visible" : "hidden"}
              variants={ani_mate2}
              transition={{ duration: 0.6, delay: 0.2 }}
              ref={ref}
              class="vividtitledesc mb-3"
            >
              {Edit_Single_Text(
                props.VividSection.titledesc,
                "VividSection.titledesc",
                props.id,
                props.activate
              )}
            </motion.div>

            <motion.div
              animate={!isDesktopOrLaptop || inView ? "visible" : "hidden"}
              variants={ani_mate2}
              transition={{ duration: 0.6, delay: 0.4 }}
              ref={ref}
              class="vividtitledesc2"
            >
              {Edit_Single_Text(
                props.VividSection.titledesc2,
                "VividSection.titledesc2",
                props.id,
                props.activate
              )}
            </motion.div>
          </div>

          <div class="row vividcontent">
            <div class="col-lg-6 col-md-12 col-sm-12  order-lg-1 order-2">
              <div class="vividcontentpara mb-3">
                {Edit_Single_Text(
                  props.VividSection.contentpara,
                  "VividSection.contentpara",
                  props.id,
                  props.activate
                )}
              </div>

              <div class="vivlistitems pt-2">
                <ul class="vivunorderedlist">
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView1 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6 }}
                    ref={ref1}
                    class="vivlistitem vivlistitem1"
                  >
                    {Edit_Single_Text(
                      props.VividSection.listitem1,
                      "VividSection.listitem1",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  {/* <motion.li
                    animate={
                      !isDesktopOrLaptop || inView2 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.1 }}
                    ref={ref2}
                    class="vivlistitem vivlistitem2"
                  >
                    {Edit_Single_Text(
                      props.VividSection.listitem2,
                      "VividSection.listitem2",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView3 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.15 }}
                    ref={ref3}
                    class="vivlistitem vivlistitem3"
                  >
                    {Edit_Single_Text(
                      props.VividSection.listitem3,
                      "VividSection.listitem3",
                      props.id,
                      props.activate
                    )}
                  </motion.li> */}
                  {/* <motion.li
                    animate={
                      !isDesktopOrLaptop || inView4 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.2 }}
                    ref={ref4}
                    class="vivlistitem vivlistitem4"
                  >
                    {Edit_Single_Text(
                      props.VividSection.listitem4,
                      "VividSection.listitem4",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView5 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.25 }}
                    ref={ref5}
                    class="vivlistitem vivlistitem5"
                  >
                    {Edit_Single_Text(
                      props.VividSection.listitem5,
                      "VividSection.listitem5",
                      props.id,
                      props.activate
                    )}
                  </motion.li> */}
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView6 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.3 }}
                    ref={ref6}
                    class="vivlistitem vivlistitem6"
                  >
                    {Edit_Single_Text(
                      props.VividSection.listitem6,
                      "VividSection.listitem6",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView7 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.35 }}
                    ref={ref7}
                    class="vivlistitem vivlistitem7"
                  >
                    {Edit_Single_Text(
                      props.VividSection.listitem7,
                      "VividSection.listitem7",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView8 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.4 }}
                    ref={ref8}
                    class="vivlistitem vivlistitem8"
                  >
                    {Edit_Single_Text(
                      props.VividSection.listitem8,
                      "VividSection.listitem8",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView9 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.45 }}
                    ref={ref9}
                    class="vivlistitem vivlistitem9"
                  >
                    {Edit_Single_Text(
                      props.VividSection.listitem9,
                      "VividSection.listitem9",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView10 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.5 }}
                    ref={ref10}
                    class="vivlistitem vivlistitem10"
                  >
                    {Edit_Single_Text(
                      props.VividSection.listitem10,
                      "VividSection.listitem10",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView11 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.55 }}
                    ref={ref11}
                    class="vivlistitem vivlistitem11"
                  >
                    {Edit_Single_Text(
                      props.VividSection.listitem11,
                      "VividSection.listitem11",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                </ul>
                {/* <div>
                  <input
                    class="vigorlearnmore"
                    type="submit"
                    value="LEARN MORE"
                  />
                </div> */}
                <div></div>
              </div>
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12 order-lg-2 order-1">
              {Edit_Image(
                props.VividSection.vividImg,
                "VividSection.vividImg",
                props.id,
                props.activate,
                "img-fluid vividImg mb-4"
              )}
            </div>
          </div>
        </div>
        <hr class="vividruler mt-4"></hr>
      </div>
    </>
  );
}
