import React, { useState, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaAngleDown } from "react-icons/fa";
import { FcExpand } from "react-icons/fc";
import "../../assets/css/nav_bar_css/hamnav.css";
import { FcCollapse } from "react-icons/fc";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import { motion} from "framer-motion";

const Hamnav = (props) => {

  const [pdc,setpdc] = useState(true)
  const [pac,setpac] = useState(true)
  const [pbss,setpbss] = useState(true)
  const [pdcf,setpdcf] = useState(true)
  const [hac,sethac] = useState(true)
  const [hacs,sethacs] = useState(true)
  const [hdcp,sethdcp] = useState(true)
  const [bsac,setbsac] = useState(true)
  const [bsdc,setbsdc] = useState(true)
  const [bsbs,setbsbs] = useState(true)








  return (
    <div
      className=" bg-white hamna"
      style={{ height: "100vh", width: "100vw", float:"right" ,overflowY:'scroll'}}
    >
     <br></br>
<div class="navb">
    <ul class="lin">
    
    <li onClick={props.handlenav} class="navhea mt-4 mb-4">
     <Link to="/Product">PRODUCTS</Link>
    </li>

     <li onClick={props.handlenav} class="navhea mt-4 mb-4">
     <Link to="/market">MARKETS</Link>
    </li>

    {/* <li onClick={props.handlenav} class="navhea mt-4 mb-4">
     <Link to="/Technology">TECHNOLOGY</Link>
    </li> */}

   
   
    
     <li onClick={props.handlenav} class="navhea mt-4 mb-4">
     <Link to="/Capabilities">CAPABILITIES</Link>
    </li>

 

    <li onClick={props.handlenav} class="navhea mt-4 mb-4">
     <Link to="/About">ABOUT US</Link>
    </li>

   
    <div onClick={props.handlenav} class=" mt-4 mb-4">
     <Link to="/Contact" className='btn-nexi'>CONTACT US</Link>
    </div>

  
    
  </ul>
</div>
</div>
     
  );
  

};

export default Hamnav;
