import firebase from "firebase";
import 'firebase/auth'


const firebaseConfig = {

  apiKey: "AIzaSyDua2WkbOhqxbysptvfT_eyolSTZXTKMQg",

  authDomain: "nexigo-8be1d.firebaseapp.com",

  projectId: "nexigo-8be1d",

  storageBucket: "nexigo-8be1d.appspot.com",

  messagingSenderId: "97486531554",

  appId: "1:97486531554:web:f0f9af779191f3ff4927bb",

  measurementId: "G-XRPSZHW1S5"

};


    firebase.initializeApp(firebaseConfig);
     firebase.analytics();

     const storage = firebase.storage()

     const db = firebase.firestore();


  export  {
   db , storage , firebase
 }