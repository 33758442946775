import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/homepage/home_Section1.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useInView } from "react-intersection-observer";
import { motion, useMotionValue } from "framer-motion";
import Slider from "react-slick";
import {
	Edit_Single_Text,
	Edit_Image,
} from "../../functions/ReuseableFunctions";
import Carousel4_icon from "../../assets/image/carousel4_icon.png";
import Carousel3_icon from "../../assets/image/carousel3_icon.png";
import Vigor from "../../assets/image/vigor.png";
import Vivid from "../../assets/image/vivid.png";
import App_vivid from "../../assets/image/app_vivid.svg";
import App_vigor from "../../assets/image/app_vigor.svg";
import icon from "../../assets/image/1.5x.png";

export default function Section1(props) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1200px)" });
	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });

	const ani_mate = {
		visible: { opacity: 1, scale: 1, y: 0 },
		hidden: {
			opacity: 0,
			y: -500,
		},
	};

	const ani_mate1 = {
		visible: { opacity: 1, scale: 1, x: 0 },
		hidden: {
			opacity: 0,
			x: 700,
		},
	};

	const ani_mate2 = {
		visible: { opacity: 1, scale: 1, y: 0 },
		hidden: {
			opacity: 0,
			y: 300,
		},
	};

	const [trigger, setTrigger] = useState({
		slideIndex: 0,
		updateCount: 0,
	});

	const settings = {
		infinite: true,
		speed: 1200,
		slidesToShow: 1,
		slidesToScroll: 1,
		pauseOnHover: false,
		autoplay: true,
		autoplaySpeed: 8000,
		beforeChange: (current, next) => setTrigger({ slideIndex: next }),
	};

	return (
		<>
			{props.Section1 && (
				<Slider {...settings} className="mb-5">
					<div
						style={{ cursor: "default" }}
						onDoubleClick={() =>
							props.activate({
								id: props.id,
								img_key: "Section1.Carousel1",
							})
						}
					>
						<div
							style={{
								backgroundImage: `url(${props.Section1.Carousel1.imgurl})`,
								backgroundPosition: "center",
								backgroundSize: "100% 100%",
								backgroundRepeat: "no-repeat",
								height: isTabletOrMobile ? "auto" : "93vh",
								width: "100vw",
							}}
						>
							<div className="row ">
								<div className="col-lg-7 col-12 ">
									<div className=" carousal_content_pos_vigor">
										<div className="d-flex flex-column ">
											<motion.div
												initial={trigger.slideIndex !== 0 && "hidden"}
												animate={
													trigger.slideIndex === 0 ? "visible" : "hidden"
												}
												variants={ani_mate1}
												transition={{
													duration: 2,
													delay: 0.8,
													type: "spring",
													bounce: 0.25,
												}}
												className="pl-1  carousel_content_3"
											>
												VIGOR
											</motion.div>

											<motion.div
												initial={trigger.slideIndex !== 0 && "hidden"}
												animate={
													trigger.slideIndex === 0 ? "visible" : "hidden"
												}
												variants={ani_mate2}
												transition={{
													duration: 2,
													delay: 0.8,
													type: "spring",
													bounce: 0.25,
												}}
												className=" p-1  carousel_content_4"
											>
												BEST IN CLASS PERFORMANCE
											</motion.div>

											<motion.div
												initial={trigger.slideIndex !== 0 && "hidden"}
												animate={
													trigger.slideIndex === 0 ? "visible" : "hidden"
												}
												variants={ani_mate2}
												transition={{
													duration: 2,
													delay: 0.8,
													type: "spring",
													bounce: 0.25,
												}}
												className=" p-1  carousel_content_5"
											>
												LITHIUM-ION BATTERY
											</motion.div>

											<motion.div
												initial={trigger.slideIndex !== 0 && "hidden"}
												animate={
													trigger.slideIndex === 0 ? "visible" : "hidden"
												}
												variants={ani_mate2}
												transition={{
													duration: 2,
													delay: 0.8,
													type: "spring",
													bounce: 0.25,
												}}
												className=" p-1"
											>
												<Link
													to="Vigorhp"
													spy={true}
													smooth={true}
													offset={-100}
												>
													<div className="btn-carousal">LEARN MORE</div>
												</Link>
											</motion.div>

                                            {!isTabletOrMobile && (
												<>
													<h3 style={{ color:"white", fontWeight: "800", marginTop: "25vh" }}>
														APPLICATIONS
													</h3>

													<img
														src={App_vigor}
														width="30%"
														className=" mt-2 img-fluid"
													></img>
												</>
											)}
										</div>
									</div>
								</div>

								<div
									className="col-12 col-lg-4 "
									style={{ paddingLeft: "0px" }}
								>
									<motion.div
										initial={trigger.slideIndex !== 0 && "hidden"}
										animate={trigger.slideIndex === 0 ? "visible" : "hidden"}
										variants={ani_mate}
										transition={{
											duration: 2,
											delay: 0.8,
											type: "spring",
											bounce: 0.25,
										}}
										className="p-1 "
										style={{ margin: "auto", display: "table" }}
									>
										<img
											src={Vigor}
											className=" w-75 img-fluid pt-lg-3 ml-5  mt-5  "
										></img>
									</motion.div>
								</div>
							</div>
						</div>
					</div>

					<div
						style={{ cursor: "default" }}
						onDoubleClick={() =>
							props.activate({
								id: props.id,
								img_key: "Section1.Carousel2",
							})
						}
					>
						<div
							style={{
								backgroundImage: `url(${props.Section1.Carousel2.imgurl})`,
								backgroundPosition: "center",
								backgroundSize: "100% 100%",
								backgroundRepeat: "no-repeat",
								height: isTabletOrMobile ? "auto" : "93vh",
								width: "100vw",
							}}
						>
							<div className="row  ">
								<div className="col-lg-7  col-12">
									<div className="carousal_content_pos_vigor">
										<div className="d-flex flex-column">
											<motion.div
												initial={trigger.slideIndex !== 1 && "hidden"}
												animate={
													trigger.slideIndex === 1 ? "visible" : "hidden"
												}
												variants={ani_mate1}
												transition={{
													duration: 2,
													delay: 0.8,
													type: "spring",
													bounce: 0.25,
												}}
												className="pl-1  carousel_content_9"
											>
												VIVID
											</motion.div>

											<motion.div
												initial={trigger.slideIndex !== 1 && "hidden"}
												animate={
													trigger.slideIndex === 1 ? "visible" : "hidden"
												}
												variants={ani_mate2}
												transition={{
													duration: 2,
													delay: 0.8,
													type: "spring",
													bounce: 0.25,
												}}
												className=" p-1  carousel_content_4"
											>
												ULTRA FAST CHARGING
											</motion.div>

											<motion.div
												initial={trigger.slideIndex !== 1 && "hidden"}
												animate={
													trigger.slideIndex === 1 ? "visible" : "hidden"
												}
												variants={ani_mate2}
												transition={{
													duration: 2,
													delay: 0.8,
													type: "spring",
													bounce: 0.25,
												}}
												className=" p-1  carousel_content_2"
											>
												HIGHLY EFFICIENT, LONG LIFE & SAFETY
											</motion.div>

											<motion.div
												initial={trigger.slideIndex !== 1 && "hidden"}
												animate={
													trigger.slideIndex === 1 ? "visible" : "hidden"
												}
												variants={ani_mate2}
												transition={{
													duration: 2,
													delay: 0.8,
													type: "spring",
													bounce: 0.25,
												}}
												className=" p-1"
											>
												<Link
													to="Vividhp"
													spy={true}
													smooth={true}
													offset={-130}
												>
													<div className=" btn-carousal">LEARN MORE</div>
												</Link>
											</motion.div>
											{!isTabletOrMobile && (
												<>
													<h3 style={{ fontWeight: "800", marginTop: "25vh" }}>
														APPLICATIONS
													</h3>

													<img
														src={App_vivid}
														width="30%"
														className=" mt-2 img-fluid"
													></img>
												</>
											)}
										</div>
									</div>
								</div>

								<div className="col-lg-5 col-12   col-6">
									<motion.div
										initial={trigger.slideIndex !== 1 && "hidden"}
										animate={trigger.slideIndex === 1 ? "visible" : "hidden"}
										variants={ani_mate}
										transition={{
											duration: 2,
											delay: 0.8,
											type: "spring",
											bounce: 0.25,
										}}
										className=" carousel_content_1"
										style={{ margin: "auto", display: "table" }}
									>
										<img
											src={Vivid}
											className="img-fluid mt-3 p-lg-5 mr-lg-5"
										></img>
									</motion.div>
								</div>
							</div>
						</div>
					</div>

					{/* 

					<div
						style={{ cursor: "default" }}
						onDoubleClick={() =>
							props.activate({
								id: props.id,
								img_key: "Section1.Carousel3",
							})
						}
					>
						<div
							style={{
								backgroundImage: `url(${props.Section1.Carousel3.imgurl})`,
								backgroundPosition: "center",
								backgroundSize: "100% 100%",
								backgroundRepeat: "no-repeat",
								height: isTabletOrMobile ? "75vw" : "93vh",
								width: "100vw",
							}}
						>
							<div className="carousal_content_pos ">
								<div className="d-flex flex-column">
									<motion.div
										initial={trigger.slideIndex !== 2 && "hidden"}
										animate={trigger.slideIndex === 2 ? "visible" : "hidden"}
										variants={ani_mate}
										transition={{
											duration: 2,
											delay: 0.8,
											type: "spring",
											bounce: 0.25,
										}}
										className="p-1"
									>
									<img src={Carousel3_icon} className="icon_size"></img>
									</motion.div>
									<motion.div
										initial={trigger.slideIndex !== 2 && "hidden"}
										animate={trigger.slideIndex === 2 ? "visible" : "hidden"}
										variants={ani_mate1}
										transition={{
											duration: 2,
											delay: 0.8,
											type: "spring",
											bounce: 0.25,
										}}
										className=" p-1 carousel_content_1"
									>
									NET ZERO CARBON FOOTPRINT
									</motion.div>
									<motion.div
										initial={trigger.slideIndex !== 2 && "hidden"}
										animate={trigger.slideIndex === 2 ? "visible" : "hidden"}
										variants={ani_mate2}
										transition={{
											duration: 2,
											delay: 0.8,
											type: "spring",
											bounce: 0.25,
										}}
										className="p-1 carousel_content_2"
									>
									ENERGY PACK FOR CIRITICAL POWER APPLICATION
									</motion.div>
                                    <motion.div
												initial={trigger.slideIndex !== 2 && "hidden"}
												animate={
													trigger.slideIndex === 2 ? "visible" : "hidden"
												}
												variants={ani_mate2}
												transition={{
													duration: 2,
													delay: 0.8,
													type: "spring",
													bounce: 0.25,
												}}
												className=" p-1"
											>
											<Link to="BMSIG" spy={true} smooth={true} offset={-100}>
                                            <div className='btn-carousal'>
											LEARN MORE
                                            </div>
											</Link>
											</motion.div>
								</div>
							</div>
						</div>
					</div>

 */}

					{/* 

					<div
						style={{ cursor: "default" }}
						onDoubleClick={() =>
							props.activate({
								id: props.id,
								img_key: "Section1.Carousel4",
							})
						}
					>
						<div
							style={{
								backgroundImage: `url(${props.Section1.Carousel4.imgurl})`,
								backgroundPosition: "center",
								backgroundSize: "100% 100%",
								backgroundRepeat: "no-repeat",
								height: isTabletOrMobile ? "75vw" : "93vh",
								width: "100vw",
							}}
						>
							<div
								className="carousal_content_pos "
							
							>
								<div className=" d-flex flex-column  ">
									<motion.div
										initial={trigger.slideIndex !== 3 && "hidden"}
										animate={trigger.slideIndex === 3 ? "visible" : "hidden"}
										variants={ani_mate}
										transition={{
											duration: 2,
											delay: 0.8,
											type: "spring",
											bounce: 0.25,
										}}
										className="p-1"
										className="p-1"
									>
										<img src={Carousel4_icon} className="icon_size"></img>
									</motion.div>
									<motion.div
										initial={trigger.slideIndex !== 3 && "hidden"}
										animate={trigger.slideIndex === 3 ? "visible" : "hidden"}
										variants={ani_mate1}
										transition={{
											duration: 2,
											delay: 0.8,
											type: "spring",
											bounce: 0.25,
										}}
										className="p-1 carousel_content_1"
									>
									    BATTERY MANAGEMENT SYSTEM
									</motion.div>
									<motion.div
										initial={trigger.slideIndex !== 3 && "hidden"}
										animate={trigger.slideIndex === 3 ? "visible" : "hidden"}
										variants={ani_mate2}
										transition={{
											duration: 2,
											delay: 0.8,
											type: "spring",
											bounce: 0.25,
										}}
										className=" p-1 carousel_content_2"
									>
									LI-ION POWER SYSTEM FOR BATTERY POWERED <br></br>INDUSTRIAL
									& COMMERICAL VEHICLES
									</motion.div>
                                    <motion.div
												initial={trigger.slideIndex !== 3 && "hidden"}
												animate={
													trigger.slideIndex === 3 ? "visible" : "hidden"
												}
												variants={ani_mate2}
												transition={{
													duration: 2,
													delay: 0.8,
													type: "spring",
													bounce: 0.25,
												}}
												className=" p-1"
											>
											<Link to="BMSAG" spy={true} smooth={true} offset={-100}>
                                            <div className='btn-carousal'>
											LEARN MORE
                                            </div>
											</Link>
											</motion.div>
								</div>
							</div>
						</div>
					</div>
				
                 */}
				</Slider>
			)}
		</>
	);
}
