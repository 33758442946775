import { useState, useEffect } from "react";
import { Button, ButtonBase } from "@material-ui/core";
import { db, storage } from "./firebase/firebase";

import firebase from "firebase";

import Resizer from "react-image-file-resizer";
import ReactCrop from "react-image-crop";
import "bootstrap/dist/css/bootstrap.css"; // or include from a CDN
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import "react-image-crop/dist/ReactCrop.css";
import rgbHex from "rgb-hex";
import RangeSlider from "react-bootstrap-range-slider";
import { AiOutlineAlignCenter,AiOutlineAlignRight,AiOutlineAlignLeft } from 'react-icons/ai';

import {
	SketchPicker,
	SwatchesPicker,
	ChromePicker,
	BlockPicker,
} from "react-color";

import { ToastContainer, toast } from "react-toastify";

function App(props) {
	const [text_Value, set_Text_Value] = useState("");
	const [text_Value_present, set_Text_Value_present] = useState("");
	const [img_Value, set_Img_Value] = useState("");
	const [color_Value, set_Color_Value] = useState(props.edit_value.color_value);

	const [blogs, setBlogs] = useState([]);
	const [reactcrop, setReactcrop] = useState([]);
	const [file, setFile] = useState(null);
	const [img, setImg] = useState("");
	const [crop, setCrop] = useState({
		unit: "%",
		width:100,
		height:100,
	});

	const [button, setButton] = useState(false);
	const [button1, setButton1] = useState(true);
	const [color, setColor] = useState("blue");
	const [add_val_1, set_add_val_1] = useState();
	const [add_data1, set_data_1] = useState();
	const [add_data2, set_data_2] = useState();
	const [add_data3, set_data_3] = useState();
	const [productdata, setproductdata] = useState();

	const toastifySuccess = () => {
		toast.success("updated!", {
			position: "bottom-right",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback success",
			toastId: "notifyToast",
		});
	};

	const toastifycancled = () => {
		toast.error("Reverted!", {
			position: "bottom-right",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback success",
			toastId: "notifyToast",
		});
	};

	const Update_text_value = (id, key) => {
		console.log(text_Value);
		db.collection(props.collection)
			.doc(id)
			.update({
				[key]: text_Value,
			});
		toastifySuccess();
		props.deactivate();
	};

	const Update_color_value = (id, key) => {
		// console.log(id)
		// console.log(key)
		// console.log(color_Value)
		db.collection(props.collection)
			.doc(id)
			.update({
				[key]: color_Value,
			});
		toastifySuccess();
		props.deactivate();
	};

	const Handle_textvalue_change = (val, keyval) => {
		set_Text_Value({ text: val });
		props.acceptValue({ ...text_Value, text: val }, keyval);
		console.log("sent");
		if (val == "") {
			setColor("red");
			setButton(true);
		} else {
			setButton(false);
			setColor("yellow");
		}
		if (
			val.length >= props.edit_value.start &&
			val.length <= props.edit_value.end
		) {
			setButton(false);
			setColor("green");
		}
	};

	const Handle_colorchange_change_text = (c, keyval, from) => {
		set_Color_Value(c);
		props.acceptValue(c, keyval);
	};

	const Handle_colorchange_change = (c, keyval) => {
		set_Color_Value("#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a));
		props.acceptValue("#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a), keyval);
	};

	const Handle_img_change = (val, keyval) => {
		setButton(false);
		props.acceptValue(val, keyval);
	};

	function CropDemo({ src }) {
		return (
			<ReactCrop
				src={src}
				crop={crop}
				onChange={(newCrop) => setCrop(newCrop)}
			/>
		);
	}

	function handleChange(e) {
		setFile(window.URL.createObjectURL(e.target.files[0]));
		CropDemo(e.target.files[0]);
	}

	function handleUpload(imgfile) {
		const uploadTask = storage.ref(`/images/${imgfile.name}`).put(imgfile);
		uploadTask.on("state_changed", console.log, console.error, () => {
			storage
				.ref("images")
				.child(imgfile.name)
				.getDownloadURL()
				.then((url) => {
					var image_value = { imgurl: url };
					setImg(image_value);
					Handle_img_change(image_value, props.edit_value.img_key);
					db.collection(props.collection)
						.doc(props.edit_value.id)
						.update({
							[props.edit_value.img_key]: image_value,
						});
					console.log(props.edit_value.img_key);
					console.log(image_value);
					props.deactivate();
				});
		});
	}

	function image_edit() {
		db.collection(props.collection)
			.doc(props.edit_value.id)
			.update({
				[props.edit_value.img_key]: img,
			});
		props.deactivate();
	}

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				3000,
				3000,
				"WEBP",
				80,
				0,
				(uri) => {
					resolve(uri);
				},
				"blob"
			);
		});

	function getCroppedImg(image, crop, fileName) {
		const canvas = document.createElement("canvas");
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = Math.ceil(crop.width * scaleX);
		canvas.height = Math.ceil(crop.height * scaleY);
		const ctx = canvas.getContext("2d");
		ctx.drawImage(
			image,
			crop.x * scaleX,
			crop.y * scaleY,
			crop.width * scaleX,
			crop.height * scaleY,
			0,
			0,
			crop.width * scaleX,
			crop.height * scaleY
		);
		// As Base64 string
		// const base64Image = canvas.toDataURL('image/jpeg');
		// As a blob
		return new Promise((resolve, reject) => {
			canvas.toBlob(
				(blob) => {
					blob.name = fileName;
					resolve(blob);
				},
				"image/png",
				1
			);
		});
	}

	const getcropurl = async (image, crop) => {
		const croppedImg = await getCroppedImg(image, crop, "newFile.png");
		croppedImg.lastModifiedDate = new Date();
		croppedImg.name = img_Value + props.edit_value.id;

		const newimage = croppedImg;
		newimage.lastModifiedDate = new Date();
		newimage.name = img_Value + props.edit_value.id;
		handleUpload(newimage);
	};

	function reset(text, img_reset) {
		if (text) {
			if (text_Value.text) {
				var txt_val = {
					text: text_Value.text,
				};
			}
			if (!text_Value.text) {
				var txt_val = {
					text: text_Value,
				};
			}
			console.log(txt_val);
			console.log(props.edit_value.key);
			props.acceptValue({ ...txt_val }, props.edit_value.key);

			set_Text_Value({ ...txt_val });
			db.collection(props.collection)
				.doc(props.edit_value.id)
				.update({
					[props.edit_value.key]: { ...txt_val },
				});

			// props.deactivate();
		}

		if (img_reset) {
			var img_val = {
				imgurl: img.imgurl,
			};
			props.acceptValue({ ...img_val }, props.edit_value.img_key);
			setImg({ ...img_val });
			db.collection(props.collection)
				.doc(props.edit_value.id)
				.update({
					[props.edit_value.img_key]: { ...img_val },
				});

			// props.deactivate();
		}
	}

	function add_data(imgfile) {
		const uploadTask = storage.ref(`/images/${imgfile.name}`).put(imgfile);
		uploadTask.on("state_changed", console.log, console.error, () => {
			storage
				.ref("images")
				.child(imgfile.name)
				.getDownloadURL()
				.then((url) => {
					var val = {};
					var key_val = {};
					if (props.edit_value.key_timeline_up) {
						key_val = props.edit_value.key_timeline_up;
						val = {
							des: add_data1,
							head: add_data2,
							img: url,
							y: add_data3,
							id: imgfile.name,
						};
						props.Add_array_Value(val, key_val);
					}

					if (props.edit_value.key_add) {
						key_val = props.edit_value.key_add;
						val = { img: url, url: add_val_1, id: imgfile.name };
						props.Add_array_Value(val, key_val);
					}

						db.collection(props.collection)
							.doc(props.edit_value.id)
							.update({
								[key_val]: firebase.firestore.FieldValue.arrayUnion(val),
							});
					

				});
		});
	}

	const getcropurl_add = async (image, crop) => {
		const croppedImg = await getCroppedImg(image, crop, "newFile.png");
		croppedImg.lastModifiedDate = new Date();
		croppedImg.name = img_Value;

		const newimage = await resizeFile(croppedImg);
		newimage.lastModifiedDate = new Date();

		newimage.name = "array_image" + Math.floor(Math.random() * 1000);
		add_data(newimage);

		// for(var i=0;i<6;i++){
		//     setTimeout(() => {
		//         newimage.name = 'clientimg' + Math.floor(Math.random()*1000)
		//         add_data(newimage)
		//     }, 3000*i);
		// }
	};

	useEffect(() => {
		if (props.edit_value.key_Trash) {
			console.log(props.collection);
			console.log(props.edit_value.item);
			console.log(props.edit_value.key_Trash);
			db.collection(props.collection)
				.doc(props.edit_value.id)
				.update(
					props.edit_value.key_Trash,
					firebase.firestore.FieldValue.arrayRemove(props.edit_value.item)
				);
			props.Remove_array_Value(
				props.edit_value.item,
				props.edit_value.key_Trash
			);
			const del = storage.ref(`/images/${props.edit_value.item.id}`);
			console.log(props.edit_value.item.id);
			del.delete();
			props.deactivate();
		}
		set_Text_Value_present(props.edit_value.value);
		set_Text_Value(props.edit_value.value);
		set_Img_Value(props.edit_value.img_key);
		setImg(props.edit_value.IMG_Value_add);
		if (props.edit_value.IMG_Value_edit)
			setImg(props.edit_value.IMG_Value_edit);
	}, []);

	return (
		<div className="row">
			<div className="col-12">
				{(props.edit_value.key_timeline_up ||
					props.edit_value.key_timeline_down) && (
					<>
						

						
                        <input
							type="text"
							value={add_val_1}
							onChange={(e) => set_data_3(e.target.value)}
							className="form-control mt-2"
							placeholder="Year"
						></input>


						<input
							type="text"
							value={add_val_1}
							onChange={(e) => set_data_1(e.target.value)}
							className="form-control mt-2"
							placeholder="Heading"
						></input>

<input
							type="text"
							value={add_val_1}
							onChange={(e) => set_data_2(e.target.value)}
							className="form-control mt-2"
							placeholder="Description"
						></input>

                        
						<div>
							<input
								type="file"
								className="mt-2 btn btn-dark"
								onChange={handleChange}
							/>

							<button
								type="submit"
								className="ml-2 m-1 btn btn-primary"
								disabled={button1}
								onClick={() => getcropurl_add(reactcrop, crop)}
							>
								update
							</button>

							<button
								type="submit"
								className="ml-2 m-1 btn btn-dark"
								variant="contained"
								onClick={() => {
									toastifycancled();
									props.deactivate();
								}}
							>
								cancle
							</button>
							<br></br>
							<ReactCrop
								src={file}
								ruleOfThirds
								crop={crop}
								onImageLoaded={(image) => setReactcrop(image)}
								onChange={(newCrop) => {
									setCrop(newCrop);
									setButton1(false);
								}}
							/>
						</div>
					</>
				)}

				{props.edit_value.key_add && (
					<>
						<textarea
							rows={1}
							value={add_val_1}
							onChange={(e) => set_add_val_1(e.target.value)}
							className="form-control"
							style={{ borderColor: color, borderWidth: "3px" }}
							autoFocus
						></textarea>

						<div>
							<input
								type="file"
								className="ml-2 btn btn-dark"
								onChange={handleChange}
							/>

							<button
								type="submit"
								className="ml-2 m-1 btn btn-dark"
								disabled={button1}
								onClick={() => getcropurl_add(reactcrop, crop)}
							>
								update
							</button>

							
							<br></br>
							<ReactCrop
								src={file}
								ruleOfThirds
								crop={crop}
								onImageLoaded={(image) => setReactcrop(image)}
								onChange={(newCrop) => {
									setCrop(newCrop);
									setButton1(false);
								}}
							/>

<button
								type="submit"
								className="ml-2 m-1 btn btn-dark"
								variant="contained"
								onClick={() => {
									toastifycancled();
									props.deactivate();
								}}
							>
								cancle
							</button>
						</div>
					</>
				)}

				{props.edit_value.key_add_product && (
					<>
						<textarea
							rows={1}
							value={add_val_1}
							onChange={(e) =>
								setproductdata({ ...productdata, name: e.target.value })
							}
							className="form-control"
							style={{ borderColor: color, borderWidth: "3px" }}
							autoFocus
						></textarea>

						<textarea
							rows={1}
							value={add_val_1}
							onChange={(e) =>
								setproductdata({ ...productdata, des: e.target.value })
							}
							className="form-control"
							style={{ borderColor: color, borderWidth: "3px" }}
							autoFocus
						></textarea>

						<textarea
							rows={1}
							value={add_val_1}
							onChange={(e) =>
								setproductdata({ ...productdata, link: e.target.value })
							}
							className="form-control"
							style={{ borderColor: color, borderWidth: "3px" }}
							autoFocus
						></textarea>

						<div>
							<input
								type="file"
								className="ml-2 btn btn-dark"
								onChange={handleChange}
							/>

							<button
								type="submit"
								className="ml-2 m-1 btn btn-dark"
								disabled={button1}
								onClick={() => getcropurl_add(reactcrop, crop)}
							>
								update
							</button>

							<button
								type="submit"
								className="ml-2 m-1 btn btn-dark"
								variant="contained"
								onClick={() => {
									toastifycancled();
									props.deactivate();
								}}
							>
								cancle
							</button>
							<br></br>
							<ReactCrop
								src={file}
								ruleOfThirds
								crop={crop}
								onImageLoaded={(image) => setReactcrop(image)}
								onChange={(newCrop) => {
									setCrop(newCrop);
									setButton1(false);
								}}
							/>
						</div>
					</>
				)}

				{text_Value_present && (
					<>
						<div className="row">
							<div className="col-12">
								<textarea
									rows={4}
									value={text_Value.text}
									onChangeCapture={(e) =>
										Handle_textvalue_change(
											e.target.value,
											props.edit_value.key
										)
									}
									className="form-control"
									style={{ borderColor: color, borderWidth: "1px" }}
									autoFocus
								></textarea>
							</div>

							<div className="col-7 text-center mt-4">
								<div>
									<ChromePicker
										color={text_Value.color}
										onChangeComplete={(c) => (
											props.acceptValue(
												{
													...text_Value,
													color:
														"#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a),
												},
												props.edit_value.key
											),
											set_Text_Value({
												...text_Value,
												color: "#" + rgbHex(c.rgb.r, c.rgb.g, c.rgb.b, c.rgb.a),
											})
										)}
										style={{ borderColor: color, borderWidth: "1px" }}
									></ChromePicker>
								</div>
							</div>

							<div className="col-5 mt-1 ">
								<div className="row ">
									<div className="col-11 p-1 rounded mt-3  bg-white">
										<div className="box_edit_font">Above : 1200px</div>
										<RangeSlider
											variant="primary"
											className="p-1"
											min="1"
											max="100"
											tooltip="auto"
											size="sm"
											value={text_Value.font_size}
											onChange={(e) => (
												props.acceptValue(
													{ ...text_Value, font_size: e.target.value },
													props.edit_value.key
												),
												set_Text_Value({
													...text_Value,
													font_size: e.target.value,
												})
											)}
										/>
									</div>

									<div className="col-11 p-1 rounded mt-2  bg-white">
										<div className="box_edit_font">FontWeight</div>
										<RangeSlider
											variant="primary"
											className="p-1"
											min="1"
											max="1000"
											tooltip="auto"
											size="sm"
											step="100"
											value={text_Value.font_weight}
											onChange={(e) => (
												props.acceptValue(
													{ ...text_Value, font_weight: e.target.value },
													props.edit_value.key
												),
												set_Text_Value({
													...text_Value,
													font_weight: e.target.value,
												})
											)}
										/>
									</div>

									<div className="col-11 p-1 rounded mt-2  bg-white">
										<div className="box_edit_font">LetterSpacing</div>
										<RangeSlider
											variant="primary"
											className="p-1"
											min="0"
											max="5"
											tooltip="auto"
											size="sm"
											step="0.1"
											value={text_Value.font_ls}
											onChange={(e) => (
												props.acceptValue(
													{ ...text_Value, font_ls: e.target.value },
													props.edit_value.key
												),
												set_Text_Value({
													...text_Value,
													font_ls: e.target.value,
												})
											)}
										/>
									</div>


                                    <div className="col-11 p-1 rounded mt-2  bg-white">
										<div className="d-flex flex-row justify-content-around">
<AiOutlineAlignLeft onClick={(e) => (
												props.acceptValue(
													{ ...text_Value, align: 'Left' },
													props.edit_value.key
												),
												set_Text_Value({
													...text_Value,
													align: 'Left',
												})
											)}/>
<AiOutlineAlignCenter onClick={(e) => (
												props.acceptValue(
													{ ...text_Value, align: 'center' },
													props.edit_value.key
												),
												set_Text_Value({
													...text_Value,
													align: 'center',
												})
											)}/>
<AiOutlineAlignRight onClick={(e) => (
												props.acceptValue(
													{ ...text_Value, align: 'right' },
													props.edit_value.key
												),
												set_Text_Value({
													...text_Value,
													align: 'right',
												})
											)}/>

                                        </div>
									</div>
								</div>
							</div>

							<div className=" container col-11 ">
								<div className="row">
									<div className="col-5 p-1 rounded mt-2  bg-white">
										<div className="box_edit_font">Padding left</div>
										<RangeSlider
											variant="primary"
											className="p-1"
											min="1"
											max="50"
											tooltip="auto"
											size="sm"
											value={text_Value.font_paddingLeft}
											onChange={(e) => (
												props.acceptValue(
													{ ...text_Value, font_paddingLeft: e.target.value },
													props.edit_value.key
												),
												set_Text_Value({
													...text_Value,
													font_paddingLeft: e.target.value,
												})
											)}
										/>
									</div>

									<div className="col-5 offset-1 p-1 rounded mt-2  bg-white">
										<div className="box_edit_font">Padding right</div>
										<RangeSlider
											variant="primary"
											className="p-1"
											min="1"
											max="50"
											tooltip="auto"
											size="sm"
											value={text_Value.font_paddingRight}
											onChange={(e) => (
												props.acceptValue(
													{ ...text_Value, font_paddingRight: e.target.value },
													props.edit_value.key
												),
												set_Text_Value({
													...text_Value,
													font_paddingRight: e.target.value,
												})
											)}
										/>
									</div>

									<div className="col-5 p-1 rounded mt-2  bg-white">
										<div className="box_edit_font">Padding top</div>
										<RangeSlider
											variant="primary"
											className="p-1"
											min="1"
											max="50"
											tooltip="auto"
											size="sm"
											value={text_Value.font_paddingTop}
											onChange={(e) => (
												props.acceptValue(
													{ ...text_Value, font_paddingTop: e.target.value },
													props.edit_value.key
												),
												set_Text_Value({
													...text_Value,
													font_paddingTop: e.target.value,
												})
											)}
										/>
									</div>

									<div className="col-5 offset-1 p-1 rounded mt-2  bg-white">
										<div className="box_edit_font">Padding bottom</div>
										<RangeSlider
											variant="primary"
											className="p-1"
											min="1"
											max="50"
											tooltip="auto"
											size="sm"
											value={text_Value.font_paddingBottom}
											onChange={(e) => (
												props.acceptValue(
													{ ...text_Value, font_paddingBottom: e.target.value },
													props.edit_value.key
												),
												set_Text_Value({
													...text_Value,
													font_paddingBottom: e.target.value,
												})
											)}
										/>
									</div>
								</div>
							</div>

							<>
								{/* <div className="container col-11 p-1 rounded mt-3  bg-white">
									<div className="box_edit_font">Left/Right</div>
									<RangeSlider
										variant="primary"
										className="p-1"
										min="-500"
										max="500"
										tooltip="on"
										size="sm"
										value={text_Value.font_pos}
										onChange={(e) => (
											props.acceptValue(
												{ ...text_Value, font_pos: e.target.value },
												props.edit_value.key
											),
											set_Text_Value({
												...text_Value,
												font_pos: e.target.value,
											})
										)}
									/>
									<div value={text_Value.font_pos} />
								</div> */}
							</>

							<div className="col-auto">
								<button
									type="submit"
									className="mt-3 m-1 btn btn-primary"
									disabled={button}
									onClick={() =>
										Update_text_value(props.edit_value.id, props.edit_value.key)
									}
								>
									update
								</button>

								<button
									type="submit"
									className="mt-3 m-1 btn btn-light"
									variant="contained"
									onClick={() => {
										toastifycancled();
										props.deactivate();
									}}
								>
									cancle
								</button>

								<button
									type="submit"
									className="mt-3 m-1 btn btn-light"
									variant="contained"
									disabled={button}
									onClick={() => {
										toastifycancled();
										reset(true, false);
									}}
								>
									reset
								</button>
							</div>
						</div>
					</>
				)}

				{(props.edit_value.IMG_Value_add ||
					(props.edit_value.img_key && !props.edit_value.IMG_Value_edit)) && (
					<div>
						<input
							type="file"
							className="ml-2 btn btn-dark"
							onChange={handleChange}
						/>

						<br></br>
						<ReactCrop
							src={file}
							ruleOfThirds
							crop={crop}
							onImageLoaded={(image) => setReactcrop(image)}
							onChange={(newCrop) => {
								setCrop(newCrop);
								setButton1(false);
							}}
						/>

						<button
							type="submit"
							className="ml-2 m-1 btn btn-primary"
							disabled={button1}
							onClick={() => getcropurl(reactcrop, crop)}
						>
							update
						</button>

						<button
							type="submit"
							className="ml-2 m-1 btn btn-light"
							variant="contained"
							onClick={() => {
								toastifycancled();
								props.deactivate();
							}}
						>
							cancle
						</button>
					</div>
				)}

				{props.edit_value.IMG_Value_edit && (
					<div>
						<div className="col-11 p-1 rounded mt-2  bg-white">
							<div className="box_edit_font">Border-Radius</div>
							<RangeSlider
								variant="primary"
								className="p-1"
								min="1"
								max="100"
								tooltip="on"
								size="sm"
								step="1"
								value={img.rad}
								onChange={(e) => (
									setImg({ ...img, rad: e.target.value }),
									setButton1(false),
									Handle_img_change(
										{ ...img, rad: e.target.value },
										props.edit_value.img_key
									)
								)}
							/>
						</div>

						<button
							type="submit"
							className="ml-2 m-1 btn btn-primary"
							disabled={button1}
							onClick={() => image_edit()}
						>
							update
						</button>

						<button
							type="submit"
							className="ml-2 m-1 btn btn-light"
							variant="contained"
							onClick={() => {
								toastifycancled();
								props.deactivate();
							}}
						>
							cancle
						</button>

						<button
							type="submit"
							className="ml-2 m-1 btn btn-light"
							variant="contained"
							onClick={() => {
								toastifycancled();
								reset(false, true);
							}}
						>
							reset
						</button>
					</div>
				)}

				{props.edit_value.color_value && (
					<>
						<ChromePicker
							color={color_Value}
							onChangeComplete={(c) =>
								Handle_colorchange_change(c, props.edit_value.key)
							}
						/>

						<input
							type="text"
							value={color_Value}
							onChange={(e) =>
								Handle_colorchange_change_text(
									e.target.value,
									props.edit_value.key,
									"textboxval"
								)
							}
							className="form-control"
							style={{ borderColor: color, borderWidth: "3px" }}
						/>

						<button
							type="submit"
							className="mt-2 m-1 btn btn-dark"
							variant="contained"
							onClick={() =>
								Update_color_value(props.edit_value.id, props.edit_value.key)
							}
						>
							update
						</button>
						<button
							type="submit"
							className="mt-2 m-1 btn btn-dark"
							variant="contained"
							onClick={() => {
								toastifycancled();
								props.deactivate();
							}}
						>
							cancle
						</button>
					</>
				)}
			</div>
		</div>
	);
}

export default App;
