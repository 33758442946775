import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useMediaQuery } from "react-responsive";
import "bootstrap/dist/css/bootstrap.min.css";
import { motion, AnimatePresence } from "framer-motion";
import Loader from "../components/Reusablecomponent/loader";
import "../assets/css/general.css";
import Footer from "../components/Reusablecomponent/footer";
import CookieConsent from "react-cookie-consent";
import Contact from "../components/Reusablecomponent/contact.js";

const Base = (props) => {
	const [timer, setTimer] = useState(true);

	const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1200px)" });

	const top = () => {
		window.scrollTo({
			top: 0,
			left: 0,
		});
	};

	useEffect(() => {
		setTimeout(() => {
			setTimer(false);
		}, 1500);
    
	}, );

	return (
		<>
			<CookieConsent
				className="shadow"
				location="bottom"
				cookieName="myAwesomeCookieName2"
				buttonText="Accept cookies"
				style={{ background: "#f0f0f0", color: "black", fontSize: "13px" }}
				buttonStyle={{
					color: "#ffff",
					background: "#f87116",
					fontSize: "14px",
					borderRadius: "40px",
				}}
				declineButtonStyle={{
					color: "#1961CB",
					background: "#ffff",
					fontSize: "14px",
					borderRadius: "40px",
				}}
				expires={150}
			>
				We use cookies to personalise and enhance your experience on our site
				and improve the delivery of ads to you. Visit our Cookie Policy to learn
				more. By clicking "accept", you agree to our use of cookies.
			</CookieConsent>

			<div style={{ overflow: "hidden" }}>
				{timer && (
					<Loader />
				) }
					<>
						<motion.div
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							transition={{ duration: 0.6 }}
						>
                            					

							{props.children}
                            <br></br>
                            <br></br>
                            <br></br>
                            {!props.ifcontact &&
                            <Contact></Contact>
}
                            <Footer/>
						</motion.div>
					</>
			

			</div>
		</>
	);
};

export default Base;
