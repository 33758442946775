import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/homepage/Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Tilty from 'react-tilty';
import { useInView } from "react-intersection-observer";
import { motion, useMotionValue } from "framer-motion";
import "react-lazy-load-image-component/src/effects/blur.css";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "../../assets/image/Nexigo.png";
import Slider from "react-slick";
import {
  Edit_Single_Text,
  Edit_Image,
} from "../../functions/ReuseableFunctions";

export default function OurExplorerSection(props) {
  const isBigScreen = useMediaQuery({ query: "(min-width: 992px)" });
  const isnotBigScreen = useMediaQuery({ query: "(max-width: 991px)" });

  const isDesktopOrLaptop = useMediaQuery({
		query: "(min-width: 1224px)",
	});


  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });
  const ani_mate = {
    visible: { opacity: 1, scale: 1, y: 0 },
    hidden: {
      opacity: 0,
      x: -250,
    },
  };

  return (
    <>
    
    {isBigScreen && ( 
      < >
      <motion.div 
                  animate={((!isDesktopOrLaptop || inView ) ? 'visible' : 'hidden' )}
                  variants={ani_mate} 
                   transition={{ duration:1}}
                   ref={ref}
                  class="container ourexplorercover">
        <div class="ourexplorertitle">
          {Edit_Single_Text(
            props.OurExplorerSection.title,
            "OurExplorerSection.title",
            props.id,
            props.activate
          )}
        </div>
        <div class="row ourexplorerthreecards">
          <div class="col-4 ourexplorercard1">
            {Edit_Image(
              props.OurExplorerSection.marketsImg,
              "OurExplorerSection.marketsImg",
              props.id,
              props.activate,
              "img-fluid marketsImg"
            )}

            <div class="markettitle">
              {Edit_Single_Text(
                props.OurExplorerSection.markettitle,
                "OurExplorerSection.markettitle",
                props.id,
                props.activate
              )}
            </div>

            <div class="markettext">
              {Edit_Single_Text(
                props.OurExplorerSection.markettext,
                "OurExplorerSection.markettext",
                props.id,
                props.activate
              )}
            </div>
            {/* <div class="explorerlearnmore mt-4 mb-4">
               Learn More
            </div> */}
          </div>
          <div class="col-4 ourexplorercard2">
            {Edit_Image(
              props.OurExplorerSection.technologyImg,
              "OurExplorerSection.technologyImg",
              props.id,
              props.activate,
              "img-fluid technologyImg"
            )}

            <div class="technologytitle">
              {Edit_Single_Text(
                props.OurExplorerSection.technologytitle,
                "OurExplorerSection.technologytitle",
                props.id,
                props.activate
              )}
            </div>

            <div class="technologytext">
              {Edit_Single_Text(
                props.OurExplorerSection.technologytext,
                "OurExplorerSection.technologytext",
                props.id,
                props.activate
              )}
            </div>

            {/* <div class="explorerlearnmore mt-4 mb-4">
               Learn More
            </div> */}

          </div>
          <div class="col-4 ourexplorercard3">
            {Edit_Image(
              props.OurExplorerSection.capabilitiesImg,
              "OurExplorerSection.capabilitiesImg",
              props.id,
              props.activate,
              "img-fluid capabilitiesImg"
            )}

            <div class="capabilitiestitle">
              {Edit_Single_Text(
                props.OurExplorerSection.capabilitiestitle,
                "OurExplorerSection.capabilitiestitle",
                props.id,
                props.activate
              )}
            </div>

            <div class="capabilitiestext">
              {Edit_Single_Text(
                props.OurExplorerSection.capabilitiestext,
                "OurExplorerSection.capabilitiestext",
                props.id,
                props.activate
              )}
            </div>

            {/* <div class="explorerlearnmore mt-4 mb-4">
               Learn More
            </div> */}

          </div>
        </div>
      </motion.div>
      </>
      )}

    {isnotBigScreen && ( 
     <div class="container  ourexplorercover">
        <div class="ourexplorertitle">
          {Edit_Single_Text(
            props.OurExplorerSection.title,
            "OurExplorerSection.title",
            props.id,
            props.activate
          )}
        </div>
        <div class="row ourexplorerthreecards">
          <div class="col-4 ourexplorercard1">
            {Edit_Image(
              props.OurExplorerSection.marketsImg,
              "OurExplorerSection.marketsImg",
              props.id,
              props.activate,
              "img-fluid marketsImg"
            )}

            <div class="markettitle">
              {Edit_Single_Text(
                props.OurExplorerSection.markettitle,
                "OurExplorerSection.markettitle",
                props.id,
                props.activate
              )}
            </div>

            <div class="markettext">
              {Edit_Single_Text(
                props.OurExplorerSection.markettext,
                "OurExplorerSection.markettext",
                props.id,
                props.activate
              )}
            </div>
            {/* <div class="explorerlearnmore mt-4 mb-4">
               Learn More
            </div> */}

          </div>
          <div class="col-4 pt-5 ourexplorercard2">
            {Edit_Image(
              props.OurExplorerSection.technologyImg,
              "OurExplorerSection.technologyImg",
              props.id,
              props.activate,
              "img-fluid technologyImg"
            )}

            <div class="technologytitle">
              {Edit_Single_Text(
                props.OurExplorerSection.technologytitle,
                "OurExplorerSection.technologytitle",
                props.id,
                props.activate
              )}
            </div>

            <div class="technologytext">
              {Edit_Single_Text(
                props.OurExplorerSection.technologytext,
                "OurExplorerSection.technologytext",
                props.id,
                props.activate
              )}
            </div>

            {/* <div class="explorerlearnmore mt-4 mb-4">
               Learn More
            </div> */}

          </div>
          <div class="col-4 pt-5 ourexplorercard3">
            {Edit_Image(
              props.OurExplorerSection.capabilitiesImg,
              "OurExplorerSection.capabilitiesImg",
              props.id,
              props.activate,
              "img-fluid capabilitiesImg"
            )}

            <div class="capabilitiestitle">
              {Edit_Single_Text(
                props.OurExplorerSection.capabilitiestitle,
                "OurExplorerSection.capabilitiestitle",
                props.id,
                props.activate
              )}
            </div>

            <div class="capabilitiestext">
              {Edit_Single_Text(
                props.OurExplorerSection.capabilitiestext,
                "OurExplorerSection.capabilitiestext",
                props.id,
                props.activate
              )}
            </div>

            {/* <div class="explorerlearnmore mt-4 mb-4">
               Learn More
            </div> */}

          </div>
        </div>
      </div>
    )}
    </>
  );
}
