import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/Marketpage/Market.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Edit_Single_Text,
  Edit_Image,
} from "../../functions/ReuseableFunctions";

export default function TitleSection(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const settings = {
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    pauseOnHover: false,
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  return (
    <>

       <div>
          {Edit_Image(
            props.TitleSection.imgundercons,
            "TitleSection.imgundercons",
            props.id,
            props.activate,
            "img-fluid imgundercons"
          )}
        </div>

      {/* <div className="markettitlecover mb-5 pb-4">
        <div>
          {Edit_Image(
            props.TitleSection.img,
            "TitleSection.img",
            props.id,
            props.activate,
            "img-fluid marketcoverimg"
          )}
        </div>
        <div className="marketstitle">
          {Edit_Single_Text(
            props.TitleSection.title,
            "TitleSection.title",
            props.id,
            props.activate
          )}
        </div>
      </div> */}
    </>
  );
}
