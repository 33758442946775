import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";
import { motion, useMotionValue } from "framer-motion";
import "../../assets/css/Aboutpage/About.css";
import "../../assets/css/Marketpage/Market.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import logo from "../../assets/image/Nexigo.png";
import Slider from "react-slick";
import {
  Edit_Single_Text,
  Edit_Image,
} from "../../functions/ReuseableFunctions";

export default function Section1(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const isDesktopOrLaptop = useMediaQuery({
		query: "(min-width: 1100px)",
	});

  const [ref, inView] = useInView({
		threshold: 0,
		triggerOnce: false,
	});

	const ani_mate = {
		visible: { opacity: 1 },
		hidden: {
			opacity: 0,
			scale: 1,
			y: 100,
		},
	};

  const [ref1, inView1] = useInView({
		threshold: 0,
		triggerOnce: false,
	});

	const ani_mate1 = {
		visible: { opacity: 1 },
		hidden: {
			opacity: 0,
			scale: 1,
			y: -100,
		},
	};

  const settings = {
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    pauseOnHover: false,
  };



  return (
	  <div>
      <div className="container cover">
        <div className="row mt-5 mb-5">
          <motion.div animate={((!isDesktopOrLaptop || inView1 ) ? 'visible' : 'hidden' )}
                   variants={ani_mate1} 
                   transition={{ duration:1}}
                   ref={ref1}
                   className="col-lg-6 col-md-12 col-sm-12  order-lg-1 order-2 p-lg-5  pt-3 p-0 ">
            {Edit_Image(
              props.Section1.img,
              "Section1.img",
              props.id,
              props.activate,
              "img-fluid marketsection1img"
            )}
          </motion.div>
          <motion.div animate={((!isDesktopOrLaptop || inView ) ? 'visible' : 'hidden' )}
                   variants={ani_mate} 
                   transition={{ duration:1}}
                   ref={ref}
                   className="levcol col-lg-6 col-md-12 col-sm-12 order-lg-2 order-1 p-5">
		  <div className="levtitle mt-4 mb-3">
            {Edit_Single_Text(
              props.Section1.levtitle,
              "Section1.levtitle",
              props.id,
              props.activate
            )}
			</div>

          <div className="levtext">
            {Edit_Single_Text(
              props.Section1.levtext,
              "Section1.levtext",
              props.id,
              props.activate
            )}
          </div>
		  </motion.div>
        </div>
        
	  </div>
	  <hr className="aboutruler"/>
	  </div>
    
  );
}
