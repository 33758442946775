import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";
import { motion, useMotionValue } from "framer-motion";
import "../../assets/css/Aboutpage/About.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

import logo from "../../assets/image/Nexigo.png";
import Slider from "react-slick";
import {
  Edit_Single_Text,
  Edit_Image,
} from "../../functions/ReuseableFunctions";

export default function Section2(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const isDesktopOrLaptop = useMediaQuery({
		query: "(min-width: 1100px)",
	});

	const ani_mate = {
		visible: { opacity: 1 },
		hidden: {
			opacity: 0,
			scale: 1,
			y: 40,
		},
	};

  const [ref2, inView2] = useInView({
		threshold: 0,
		triggerOnce: false,
	});

  const [ref3, inView3] = useInView({
		threshold: 0,
		triggerOnce: false,
	});

  const [ref4, inView4] = useInView({
		threshold: 0,
		triggerOnce: false,
	});
  const [ref5, inView5] = useInView({
		threshold: 0,
		triggerOnce: false,
	});

  const [ref6, inView6] = useInView({
		threshold: 0,
		triggerOnce: false,
	});

  const [ref7, inView7] = useInView({
		threshold: 0,
		triggerOnce: false,
	});

	const ani_mate1 = {
		visible: { opacity: 1 },
		hidden: {
			opacity: 0,
			scale: 1,
			x: -100,
		},
	};

  

  const settings = {
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    pauseOnHover: false,
  };

  return (
    <>
    <div className="core pt-5 pb-5">

    <motion.div    animate={((!isDesktopOrLaptop || inView2 ) ? 'visible' : 'hidden' )}
                   variants={ani_mate} 
                   transition={{ duration:.5}}
                   ref={ref2}
                   className="corevaluetitle mb-3">
    {Edit_Single_Text(
                props.CoreValues.title,
                "CoreValues.title",
                props.id,
                props.activate
              )}

    </motion.div>

    <motion.div    animate={((!isDesktopOrLaptop || inView2 ) ? 'visible' : 'hidden' )}
                   variants={ani_mate} 
                   transition={{ duration:.5}}
                   ref={ref2}
                   className="corevaluetext">
    {Edit_Single_Text(
                props.CoreValues.corevaluetext,
                "CoreValues.corevaluetext",
                props.id,
                props.activate
              )}

    </motion.div>

    </div>
    <div className="container corevaluecontain mt-3">
    <motion.div    animate={((!isDesktopOrLaptop || inView3 ) ? 'visible' : 'hidden' )}
                   variants={ani_mate1} 
                   transition={{ duration:1}}
                   ref={ref3}
                   className="row mb-3">
       <div className=" aboutimgcol col-2">
       {Edit_Image(
              props.CoreValues.qualityImg,
              "CoreValues.qualityImg",
              props.id,
              props.activate,
              "img-fluid Img ml-3 mt-3"
            )}
       </div>
       <div className="abouttextcol col-10">
       <div className="title mb-2">
       {Edit_Single_Text(
              props.CoreValues.qualitytitle,
              "CoreValues.qualitytitle",
              props.id,
              props.activate
            )}
        </div>
        <div className="unordered list">
            <ul className="list">
            <li>
            <div className="Points pb-1">
       {Edit_Single_Text(
              props.CoreValues.qualityPoint1,
              "CoreValues.qualityPoint1",
              props.id,
              props.activate
            )}
        </div>
        </li>
        <li>
        <div className="Points">
       {Edit_Single_Text(
              props.CoreValues.qualityPoint2,
              "CoreValues.qualityPoint2",
              props.id,
              props.activate
            )}
        </div>
        </li>
            </ul>
        </div>
        </div>
    </motion.div>
    <motion.div  animate={((!isDesktopOrLaptop || inView4 ) ? 'visible' : 'hidden' )}
                   variants={ani_mate1} 
                   transition={{ duration:1,delay:0.2}}
                   ref={ref4}
                   className="row mb-3">
       <div className="col-2 aboutimgcol">
       {Edit_Image(
              props.CoreValues.reliabilityImg,
              "CoreValues.reliabilityImg",
              props.id,
              props.activate,
              "img-fluid Img ml-3 mt-3"
            )}
       </div>
       <div className="col-10 abouttextcol">
       <div className="title mb-2">
       {Edit_Single_Text(
              props.CoreValues.reliabilitytitle,
              "CoreValues.reliabilitytitle",
              props.id,
              props.activate
            )}
        </div>
        <div className="unordered list">
            <ul className="list">
            <li>
            <div className="Points pb-1">
       {Edit_Single_Text(
              props.CoreValues.reliabilityPoint1,
              "CoreValues.reliabilityPoint1",
              props.id,
              props.activate
            )}
        </div>
        </li>
        <li>
        <div className="Points">
       {Edit_Single_Text(
              props.CoreValues.reliabilityPoint2,
              "CoreValues.reliabilityPoint2",
              props.id,
              props.activate
            )}
        </div>
        </li>
            </ul>
        </div>
        </div>
    </motion.div>
    <motion.div  animate={((!isDesktopOrLaptop || inView5 ) ? 'visible' : 'hidden' )}
                   variants={ani_mate1} 
                   transition={{ duration:1, delay:0.4}}
                   ref={ref5}
                   className="row mb-3">
       <div className="col-2 aboutimgcol">
       {Edit_Image(
              props.CoreValues.affordabilityImg,
              "CoreValues.affordabilityImg",
              props.id,
              props.activate,
              "img-fluid Img ml-3 mt-3"
            )}
       </div>
       <div className="col-10 abouttextcol">
       <div className="title mb-2">
       {Edit_Single_Text(
              props.CoreValues.affordabilitytitle,
              "CoreValues.affordabilitytitle",
              props.id,
              props.activate
            )}
        </div>
        <div className="unordered list">
            <ul className="list">
            <li>
            <div className="Points pb-1">
       {Edit_Single_Text(
              props.CoreValues.affordabilityPoint1,
              "CoreValues.affordabilityPoint1",
              props.id,
              props.activate
            )}
        </div>
        </li>
        <li>
        <div className="Points">
       {Edit_Single_Text(
              props.CoreValues.affordabilityPoint2,
              "CoreValues.affordabilityPoint2",
              props.id,
              props.activate
            )}
        </div>
        </li>
            </ul>
        </div>
        </div>
    </motion.div>
    <motion.div  animate={((!isDesktopOrLaptop || inView6 ) ? 'visible' : 'hidden' )}
                   variants={ani_mate1} 
                   transition={{ duration:1,delay:0.6}}
                   ref={ref6}
                   className="row mb-3">
       <div className="col-2 aboutimgcol">
       {Edit_Image(
              props.CoreValues.innovativeImg,
              "CoreValues.innovativeImg",
              props.id,
              props.activate,
              "img-fluid Img ml-3 mt-4"
            )}
       </div>
       <div className="col-10 abouttextcol">
       <div className="title mb-2">
       {Edit_Single_Text(
              props.CoreValues.innovativetitle,
              "CoreValues.innovativetitle",
              props.id,
              props.activate
            )}
        </div>
        <div className="unordered list">
            <ul className="list">
            <li>
            <div className="Points pb-1">
       {Edit_Single_Text(
              props.CoreValues.innovativePoint1,
              "CoreValues.innovativePoint1",
              props.id,
              props.activate
            )}
        </div>
        </li>
        <li>
        <div className="Points">
       {Edit_Single_Text(
              props.CoreValues.innovativePoint2,
              "CoreValues.innovativePoint2",
              props.id,
              props.activate
            )}
        </div>
        </li>
        <li>
        <div className="Points">
       {Edit_Single_Text(
              props.CoreValues.innovativePoint3,
              "CoreValues.innovativePoint3",
              props.id,
              props.activate
            )}
        </div>
        </li>
            </ul>
        </div>
        </div>
    </motion.div>
    <motion.div  animate={((!isDesktopOrLaptop || inView7 ) ? 'visible' : 'hidden' )}
                   variants={ani_mate1} 
                   transition={{ duration:1,delay:0.8}}
                   ref={ref7}
                   className="row mb-3">
       <div className="col-2 aboutimgcol">
       {Edit_Image(
              props.CoreValues.delightImg,
              "CoreValues.delightImg",
              props.id,
              props.activate,
              "img-fluid Img ml-3 mt-4"
            )}
       </div>
       <div className="col-10 abouttextcol">
       <div className="title mb-2">
       {Edit_Single_Text(
              props.CoreValues.delighttitle,
              "CoreValues.delighttitle",
              props.id,
              props.activate
            )}
        </div>
        <div className="unordered list">
            <ul className="list">
            <li>
            <div className="Points pb-1">
       {Edit_Single_Text(
              props.CoreValues.delightPoint1,
              "CoreValues.delightPoint1",
              props.id,
              props.activate
            )}
        </div>
        </li>
        <li>
        <div className="Points">
       {Edit_Single_Text(
              props.CoreValues.delightPoint2,
              "CoreValues.delightPoint2",
              props.id,
              props.activate
            )}
        </div>
        </li>
        <li>
        <div className="Points">
       {Edit_Single_Text(
              props.CoreValues.delightPoint3,
              "CoreValues.delightPoint3",
              props.id,
              props.activate
            )}
        </div>
        </li>
        <li>
        <div className="Points">
       {Edit_Single_Text(
              props.CoreValues.delightPoint4,
              "CoreValues.delightPoint4",
              props.id,
              props.activate
            )}
        </div>
        </li>
            </ul>
        </div>
        </div>
    </motion.div>
    </div>
    </>
  );
}
