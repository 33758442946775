import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch,useLocation, Route } from "react-router-dom";
import Home from "./core/Home";
import About from "./core/About";
import Contact from "./core/ContactUs";
import Product from "./core/Product";
import Market from "./core/Market";
import Capabilities from "./core/Capabilities";
import Technology from "./core/Technology";
import Menu from "./core/Menu";
import { isAuthenticated, isAdmin } from "./functions/ReuseableFunctions";
import Loader from "./components/Reusablecomponent/loader";


const Routes = () => {
	const [timer, setTimer] = useState(true);
	const [admin, setAdmin] = useState(false);

    const load = () => {
        console.log('loaded')
		setTimer(false);
		setTimeout(() => {
			setTimer(true);
		}, 1000);
	};

    useEffect(() => {
        setTimeout(() => {
			isAdmin().then( (val) => {
				setAdmin(val);
			});
		}, 1000);
       
    }, [timer])


  return (
      <>
    <BrowserRouter>
    <div style={{ overflow: "hidden" }}>

    <Menu load={load}/>
{timer ?
    
          <Switch >
      <Route path="/" exact  component={Home} />
      <Route path="/About" exact  component={About} />
      <Route path="/Contact" exact  component={Contact} />
      <Route path="/Product" exact  component={Product} />
      <Route path="/Market" exact  component={Market} />
      <Route path="/Technology" exact  component={Technology} />
      <Route path="/Capabilities" exact  component={Capabilities} />
      </Switch>
          :
          <Loader></Loader>
    }
    
     </div>
    </BrowserRouter>

    </>
  );
};

export default Routes;
