
// import  "../../assest/css/loader.css";
// import load from "../../assest/loading.gif";
// const Loading = () => {

// return(
//     <>
//     <div className="body1">

//     <img src={load} style={{width:'240px',height:'220px'}}/>
//     <div></div>
//     <div></div>
//   </div>  
  
//     </>
// )

// }
// export default Loading;





import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useMediaQuery } from "react-responsive";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function Loading() {
  const classes = useStyles();
  const isBigScreen = useMediaQuery({ query: "(min-width: 1350px)" });

  return (
    <div>
    <div className={classes.root} >
      <LinearProgress />
      <div style={{height:'100vh'}}></div>
    </div>
    </div>
  );
}