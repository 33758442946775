import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Routes from './Routes';
import reportWebVitals from './reportWebVitals';
import {Helmet} from "react-helmet";
import ReactDOMServer from 'react-dom/server';



ReactDOM.render(
  <React.StrictMode>
    <Routes/>
  </React.StrictMode>,
  document.getElementById('root')
);






