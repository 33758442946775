import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/Aboutpage/About.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import AOS from "aos";
import "aos/dist/aos.css";
import logo from "../../assets/image/Nexigo.png";
import Slider from "react-slick";
import {
  Edit_Single_Text,
  Edit_Image,
} from "../../functions/ReuseableFunctions";

export default function TitleSection(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const settings = {
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    pauseOnHover: false,
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  return (
    <>
      <div className="titlecover mb-5 pb-4">
        <div>
          {Edit_Image(
            props.TitleSection.img,
            "TitleSection.img",
            props.id,
            props.activate,
            "img-fluid aboutcoverimg"
          )}
        </div>
        <div className="abouttitle">
          {Edit_Single_Text(
            props.TitleSection.title,
            "TitleSection.title",
            props.id,
            props.activate
          )}
        </div>

        <div className="abouttitleText">
          {Edit_Single_Text(
            props.TitleSection.titleText,
            "TitleSection.titleText",
            props.id,
            props.activate
          )}
        </div>
      </div>
    </>
  );
}
