import React, { useState, useEffect, useRef } from "react";
import { Link, Prompt, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../../assets/image/Nexigo.png";
import "../../assets/css/nav_bar_css/Navbarbigscreen.css";
import { motion, AnimatePresence } from "framer-motion";
import { ToastContainer, toast } from "react-toastify";
import Signinnav from "./signinnav";
import Hamnav from "./hamnav";
import { CgMenuRightAlt } from "react-icons/cg";

import {
	isAdmin,
	isAuthenticated,
	login,
	logout,
	emaillogin,
} from "../../functions/ReuseableFunctions";

export default function Navbarbigscreen(props) {
	const [userdata, setUserdata] = useState("");
	const [checklogged, setChecklogged] = useState(false);
	const [isadmin, setAdmin] = useState("");
	const [hamnav, sethamnav] = useState(false);

	const toastifylogout = () => {
		toast.error("logout Sucessfull!", {
			position: "bottom-right",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback danger",
			toastId: "notifyToast",
		});
	};

	const toastifylogin = () => {
		toast.success("login sucessfull!", {
			position: "bottom-right",
			autoClose: 2000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback success",
			toastId: "notifyToast",
		});
	};

	const call_logout = async () => {
		logout().then(function (log) {
            props.load()
			if (log)
				setTimeout(() => {
					setUserdata("");
					setAdmin(false);

					toastifylogout();
				}, 2000);
		});
	};

	const call_login = async () => {
		login().then(function (log) {
            props.load()
			if (log != null)
				setTimeout(() => {
					isAuthenticated().then(function (val) {
						if (val) {
							console.log(val);
							setUserdata({ ...val });
							isAdmin().then(function (val) {
								setAdmin(val);
							});
						}
					});
					toastifylogin();
				}, 2000);
		});
	};

	function close_login_popup() {
		setChecklogged(!checklogged);
	}

    
    const setfalsehamnav = () => {
		sethamnav(false);
	}

	useEffect(() => {
		setTimeout(() => {
			isAuthenticated().then(function (val) {
				if (val) {
					setUserdata({ ...val });
				}
			});
		}, [1500]);
	}, [checklogged]);

	return (
		<>
			<div className="Navbarbigscreen bg-white shadow">
				<div className="row ">
					<div className="col-2">
						<div>
							<Link to="/">
								<LazyLoadImage src={logo} className="navlogo" />
							</Link>
						</div>
					</div>
					<div className="col-10">
						<div className="row justify-content-end">
							<div
								className="col-auto ham"
								onClick={() => (setChecklogged(false),sethamnav(!hamnav))}
							>
								<CgMenuRightAlt size='24px' color='rgb(231, 118, 12)'/>
							</div>
							{userdata && (
								<div
									className="col-auto "
									onClick={() => (sethamnav(false),setChecklogged(!checklogged))}
								>
									<Link to="/" className="navuser pr-xl-5 pr-lg-3 pr-3">
										<img className="user" src={userdata.photoURL}></img>
									</Link>
								</div>
							)}

							{!userdata && (
								<div
									className="col-auto"
									onClick={() => (sethamnav(false),setChecklogged(!checklogged))}
								>
									<Link to="/" className="navuser pr-xl-5 pr-lg-3 pr-3">
										<img
											className="user"
											src="https://freepngimg.com/thumb/google/66726-customer-account-google-service-button-search-logo.png"
										></img>
									</Link>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
            <AnimatePresence>
						{hamnav &&  (
							<motion.div
								initial={{ x: 1000, opacity: 1 }}
								animate={{ x: 0, opacity: 1 }}
								exit={{ x: 1000, opacity: 1 }}
								transition={{ duration: 0.6 }}
								className="sticky"
								style={{ Zindex: "1!important" }}
							>
							<Hamnav handlenav={setfalsehamnav}></Hamnav>
							</motion.div> 
						)}
					</AnimatePresence>

			<AnimatePresence>
				{checklogged && (
					<motion.div
						initial={{ x: 700, opacity: 1 }}
						animate={{ x: 0, opacity: 1 }}
						exit={{ x: 700, opacity: 1 }}
						transition={{ duration: 0.6 }}
						className="sticky"
						style={{ Zindex: "1!important" }}
					>
						<Signinnav
							close_login_popup={close_login_popup}
							login={call_login}
							logout={call_logout}
							userin={userdata}
						></Signinnav>
					</motion.div>
				)}
			</AnimatePresence>


            

		</>
	);
}
