import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";
import { motion, useMotionValue } from "framer-motion";
import "../../assets/css/ContactUspage/ContactUs.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import logo from "../../assets/image/Nexigo.png";
import {
  Edit_Single_Text,
  Edit_Image,
} from "../../functions/ReuseableFunctions";

export default function ContactUsSection(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1100px)",
  });

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const ani_mate = {
    visible: { opacity: 1 },
    hidden: {
      opacity: 0,
      scale: 1,
      y: 100,
    },
  };


  const settings = {
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    pauseOnHover: false,
  };

  return (
    <div class="contactUscover p-4">
    <div class="contactUstitle p-3">
      {Edit_Single_Text(
        props.ContactUsSection.title,
        "ContactUsSection.title",
        props.id,
        props.activate
      )}
      </div>
      <div class="contactUscontent p-3">
      {Edit_Single_Text(
        props.ContactUsSection.content,
        "ContactUsSection.content",
        props.id,
        props.activate
      )}
      </div>
    </div>
  );
}
