import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";
import { motion, useMotionValue } from "framer-motion";
import "../../assets/css/Aboutpage/About.css";
import "../../assets/css/Marketpage/Market.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Carousel from "react-bootstrap/Carousel";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import logo from "../../assets/image/Nexigo.png";
import Slider from "react-slick";
import {
  Edit_Single_Text,
  Edit_Image,
} from "../../functions/ReuseableFunctions";

export default function Section2(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const isDesktopOrLaptop = useMediaQuery({
		query: "(min-width: 1100px)",
	});


  const [ref1, inView1] = useInView({
		threshold: 0,
		triggerOnce: false,
	});

	const ani_mate1 = {
		visible: { opacity: 1 },
		hidden: {
			opacity: 0,
			scale: 1,
			x: 500,
		},
	};

  const settings = {
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    fade: true,
    pauseOnHover: false,
  };



  return (
	  <div>
      <div className="container cover">
        <div className="row">
          <div className="ivcol col-lg-6 col-md-12 col-sm-12 p-lg-3  pt-3 p-0">
            <div className="ivtitle  mb-4">
              {Edit_Single_Text(
                props.Section2.ivtitle,
                "Section2.ivtitle",
                props.id,
                props.activate
              )}
            </div>
            <div className="ivtext">
              {Edit_Single_Text(
                props.Section2.ivtext,
                "Section2.ivtext",
                props.id,
                props.activate
              )}
            </div>
          </div>
          <motion.div animate={((!isDesktopOrLaptop || inView1 ) ? 'visible' : 'hidden' )}
                   variants={ani_mate1} 
                   transition={{ duration:3.5}}
                   ref={ref1}
                   className="col-lg-6 col-md-12 col-sm-12 p-3">
            {Edit_Image(
              props.Section2.img,
              "Section2.img",
              props.id,
              props.activate,
              "img-fluid marketsection2img"
            )}
          </motion.div>
        </div>        
	  </div>
	  <hr className="aboutruler"/>
	  </div>
    
  );
}
