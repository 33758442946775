import React, { useState, useEffect } from "react";
import { Link, Prompt, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import footer_logo from "../../assets/image/footer_logo.png";
import { useMediaQuery } from "react-responsive";
import { FiFacebook,FiInstagram,FiLinkedin } from 'react-icons/fi';

export default function Footer(props) {
	const tab_or_smaller = useMediaQuery({ query: "(min-width: 900px)" });

	return (
		<>
        
			<div className="pt-lg-3 pb-4 shadow ">
				<div className="row justify-content-center  pt-lg-5 pt-md-3 pb-3 rounded">
					<div className="col-lg-auto col-12 pr-lg-5 pb-2 text-center ">
						<Link to="/">
							<LazyLoadImage src={footer_logo} className="footer_logo" />
						</Link>
					</div>
					<div className="col-auto pl-lg-0 pl-5">
					
							<div className="row">
                            <div className="col-lg-auto  col-6 pr-5">
									<div className="footer_heading">PRODUCT</div>
									<Link className="footer_description" to="/">
										Battery Packs
									</Link>
									<br></br>
									<Link className="footer_sub_description" to="/">
										Vivid
									</Link>
									<br></br>
									<Link className="footer_sub_description" to="/">
										Vigor
									</Link>
									<br></br>
									{/* <Link className="footer_description" to="/">
										BMS
									</Link> */}
									<br></br>
									{/* <Link className="footer_sub_description" to="/">
										Industrial
									</Link> */}
									<br></br>
									{/* <Link className="footer_sub_description" to="/">
									</Link> */}
									<br></br>
								</div>

								<div className="col-lg-auto  col-6 pr-5">
									<div className="footer_heading">MARKETS</div>
									<Link className="footer_description" to="/market">
										Light Electric Vehicle
									</Link>
									<br></br>
									<Link className="footer_description" to="/market">
										Industrial Vehicle
									</Link>
									<br></br>
									<Link className="footer_description" to="/market">
										Energy Storage
									</Link>
									<br></br>
								</div>

								{/* <div className="col-lg-auto  col-6 pr-5 mt-4 mt-lg-0">
									<div className="footer_heading">TECHNOLOGY</div>
									<Link className="footer_description" to="/">
										Ultra Fast Charging
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										Ultra Capacitors
									</Link>
									<br></br>
									<Link className="footer_description" to="/">
										Battery management system
									</Link>
									<br></br>
								</div> */}

								<div className="col-lg-auto  col-6 pr-5 mt-4 mt-lg-0">
									<div className="footer_heading">CAPABILITIES</div>
									<Link className="footer_description" to="/Capabilities">
										Innovation
									</Link>
									<br></br>
									<Link className="footer_description" to="/Capabilities">
										Custom Design
									</Link>
									<br></br>
									<Link className="footer_description" to="/Capabilities">
                                    State of the Art R&D LAB
									</Link>
									<br></br>
									
								</div>
                                {tab_or_smaller && (


								<div className="col-auto  pr-5 ">
									<div className="footer_heading">ABOUT Us</div>
									<Link className="footer_description" to="/About">
										Vision & Mission
									</Link>
									<br></br>
									<Link className="footer_description" to="/About">
										Core Values
									</Link>
									<br></br>
									{/* <Link className="footer_description" to="/About">
										Leadership
									</Link> */}
									<br></br>
								</div>
                                )}
							</div>
					</div>
                    						


					<div className="col-12 pr-5 pl-5">
                    <hr></hr>
						<div className=" d-flex  ">
							<div className="footer_description flex-grow-1" to="/">
								© 2021 Nexigo Energy
							</div>
							<div className="footer_description" to="/">
                            <a href='https://www.linkedin.com/company/78280321' className="text-black"><FiLinkedin size='22px'/></a>
							</div>
							<br></br>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
