import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";
import { motion, useMotionValue } from "framer-motion";
import "../../assets/css/ContactUspage/ContactUs.css";
import "../../assets/css/ContactUspage/contact-sent.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import logo from "../../assets/image/Nexigo.png";
import {
	validation_mobile_number,
	validation_email,
	validation_name,
	validation_title,
	validation_company,
	validation_country,
	validation_comment,
	validation_inquiry,
	emaillogin,
} from "../../functions/ReuseableFunctions";
import { ToastContainer, toast } from "react-toastify";
import validator from "validator";
import axios from "axios";

export default function FormSection(props) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

	const [form_values, setForm_values] = useState({
		firstname: "not_selected",
		lastname: "not_selected",
		Title: "not_selected",
		email: "not_selected",
		phone: "not_selected",
		company: "not_selected",
		country: "not_selected",
		inquiry: "not_selected",
		comment: "not_selected",
		checkbox: true,
		success: false,
	});

	const [success_popup, SetSuccess_popup] = useState(false);

	const toastifySuccess = () => {
		toast.success("Form sent!", {
			position: "bottom-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback success",
			toastId: "notifyToast",
		});
	};

	const toastifyfail = () => {
		toast.error("Please enter vaild  details", {
			position: "bottom-right",
			autoClose: 4000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback success",
			toastId: "notifyToast",
		});
	};

	function resend() {
		setForm_values({
			firstname: "not_selected",
			lastname: "not_selected",
			Title: "not_selected",
			email: "not_selected",
			phone: "not_selected",
			company: "not_selected",
			country: "not_selected",
			inquiry: "not_selected",
			comment: "not_selected",
			checkbox: false,
			success: false,
		});
		SetSuccess_popup(false);
	}

	function Success_popup() {
		return (
			<div className="">
				<motion.div
					initial={{ opacity: 0, y: 100 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ scale: 0.65, opacity: 0 }}
					transition={{ duration: 1 }}
					className="d-flex flex-column justify-content-center container-lg"
				>
					{" "}
					<hr className="contactformruler" />
					{form_values.success && (
						<>
							<div className="pr-lg-5 pl-lg-5 pb-4 pt-4">
								<img
									width="25%"
									src="https://image.flaticon.com/icons/png/512/3587/3587986.png"
								/>
							</div>

							<h3 class="">Sent!</h3>
							<p class="h5 p-2">
								Thanks for your product inquiry,a copy has been mailed to you.
							</p>
							<p>
								Our marketing team will get back to you within the next 48
								hours.
							</p>
							<div class="btn-nexi mt-3" onClick={resend}>
								OK
							</div>
							<div class="modal-footer"></div>
						</>
					)}
				</motion.div>
			</div>
		);
	}

	function form_validate() {
		let val = false;
		var value = { ...form_values };
		if (
			validation_name(form_values.firstname).class == "pass" &&
			validation_title(form_values.Title).class == "pass" &&
			validation_name(form_values.lastname).class == "pass" &&
			validation_mobile_number(form_values.phone).class == "pass" &&
			validation_company(form_values.company).class == "pass" &&
			validation_email(form_values.email).class == "pass" &&
			validation_company(form_values.country).class == "pass" &&
			validation_inquiry(form_values.inquiry).class == "pass" &&
			validation_comment(form_values.comment).class == "pass_text_area"
		) {
			val = true;
		} else {
            toastifyfail();

			if (form_values.firstname == "not_selected") {
				value = { ...value, firstname: "" };
			}
			if (form_values.Title == "not_selected") {
				value = { ...value, Title: "" };
			}
			if (form_values.lastname == "not_selected") {
				value = { ...value, lastname: "" };
			}
			if (form_values.phone == "not_selected") {
				value = { ...value, phone: "" };
			}
			if (form_values.company == "not_selected") {
				value = { ...value, company: "" };
			}
			if (form_values.email == "not_selected") {
				value = { ...value, email: "" };
			}
			if (form_values.country == "not_selected") {
				value = { ...value, country: "" };
			}
			if (form_values.comment == "not_selected") {
				value = { ...value, comment: "" };
			}
		}
		setForm_values(value);
		return val;
	}

	function sendEmail(e) {
		e.preventDefault();
		console.log(form_validate());
		if (form_validate()) {
			SetSuccess_popup(true);
			axios({
				method: "POST",
				url: " https://us-central1-nexigo-8be1d.cloudfunctions.net/app/api/sendmail_maincontact",
				data: form_values,
				crossorigin: true,
			}).then((response) => {
				console.log(response.data);
				if (response.data === "success!") {
					toastifySuccess();
					setTimeout(() => {
						setForm_values({
							firstname: "not_selected",
							lastname: "not_selected",
							Title: "not_selected",
							email: "not_selected",
							phone: "not_selected",
							company: "not_selected",
							country: "not_selected",
							inquiry: "not_selected",
							comment: "not_selected",
							checkbox: false,
							success: true,
						});
					}, 2000);
				} else if (response.data === "fail!") {
					alert("Message failed to send.");
				}
			});
		} else {
			toastifyfail();
			SetSuccess_popup(false);
		}
	}

	const isDesktopOrLaptop = useMediaQuery({
		query: "(min-width: 1100px)",
	});

	return (
		<div>
			{form_values.success && (
				<div className="text-center">{Success_popup()}</div>
			)}
			{!form_values.success && (
				<>
					<div class="formcontainer mt-3">
						<form>
							<div class="row">
								<div class="in-form1">
									<label for="fname">
										First Name<span class="contactrequired">*</span>
									</label>
									<br />
									<input
										type="text"
										onBlur={(e) =>
											setForm_values({
												...form_values,
												firstname: e.target.value,
											})
										}
										onChange={(e) =>
											setForm_values({
												...form_values,
												firstname: e.target.value,
											})
										}
										className={validation_name(form_values.firstname).class}
										id="fname"
										name="firstname"
										placeholder="Your first name.."
										required
									/>
									<div style={{ marginTop: "-10px" }}>
										{validation_name(form_values.firstname).msg}
									</div>{" "}
								</div>

								<div class="in-form2">
									<label for="lname">
										Last Name<span class="contactrequired">*</span>
									</label>
									<br />
									<input
										type="text"
										onBlur={(e) =>
											setForm_values({
												...form_values,
												lastname: e.target.value,
											})
										}
										onChange={(e) =>
											setForm_values({
												...form_values,
												lastname: e.target.value,
											})
										}
										className={validation_name(form_values.lastname).class}
										id="lname"
										name="lastname"
										placeholder="Your last name.."
										required
									/>
									<div style={{ marginTop: "-10px" }}>
										{validation_name(form_values.lastname).msg}
									</div>{" "}
								</div>
							</div>
							<br />

							<div>
								<label for="Title">Title</label>
								<br />
								<input
									type="text"
									onChange={(e) =>
										setForm_values({ ...form_values, Title: e.target.value })
									}
									className={validation_title(form_values.Title).class}
									id="Title"
									name="Title"
									placeholder="Title.."
								/>
								<div style={{ marginTop: "-10px" }}>
									{validation_title(form_values.Title).msg}
								</div>{" "}
							</div>
							<br />

							<div>
								<label for="email">
									Email<span class="contactrequired">*</span>
								</label>
								<br />
								<input
									type="text"
									onBlur={(e) =>
										setForm_values({ ...form_values, email: e.target.value })
									}
									onChange={(e) =>
										setForm_values({ ...form_values, email: e.target.value })
									}
									className={validation_email(form_values.email).class}
									id="email"
									name="email"
									placeholder="Your email.."
									required
								/>
								<div style={{ marginTop: "-10px" }}>
									{validation_email(form_values.email).msg}
								</div>{" "}
							</div>

							<br />

							<div>
								<label for="phone">
									Phone<span class="contactrequired">*</span>
								</label>
								<br />
								<input
									type="text"
									onBlur={(e) =>
										setForm_values({ ...form_values, phone: e.target.value })
									}
									onChange={(e) =>
										setForm_values({ ...form_values, phone: e.target.value })
									}
									className={validation_mobile_number(form_values.phone).class}
									id="phone"
									name="phone"
									placeholder="Your phone number.."
									required
								/>
								<div style={{ marginTop: "-10px" }}>
									{validation_mobile_number(form_values.phone).msg}
								</div>{" "}
							</div>
							<br />

							<div>
								<label for="company">
									Company<span class="contactrequired">*</span>
								</label>
								<br />
								<input
									type="text"
									onBlur={(e) =>
										setForm_values({ ...form_values, company: e.target.value })
									}
									onChange={(e) =>
										setForm_values({ ...form_values, company: e.target.value })
									}
									className={validation_company(form_values.company).class}
									id="company"
									name="company"
									placeholder="Your company"
									required
								/>
								<div style={{ marginTop: "-10px" }}>
									{validation_company(form_values.company).msg}
								</div>{" "}
							</div>
							<br />

							<div>
								<label for="country">
									Country<span class="contactrequired">*</span>
								</label>
								<br />
								<input
									type="text"
									onBlur={(e) =>
										setForm_values({ ...form_values, country: e.target.value })
									}
									onChange={(e) =>
										setForm_values({ ...form_values, country: e.target.value })
									}
									className={validation_company(form_values.country).class}
									id="country"
									name="country"
									placeholder="Your country.."
									required
								/>
								<div style={{ marginTop: "-10px" }}>
									{validation_company(form_values.country).msg}
								</div>{" "}
							</div>
							<br />

							<div>
								<label for="inquiry">Inquiry type </label>
								<br />
								<input
									type="text"
									className={validation_inquiry(form_values.inquiry).class}
									onBlur={(e) =>
										setForm_values({ ...form_values, inquiry: e.target.value })
									}
									onChange={(e) =>
										setForm_values({ ...form_values, inquiry: e.target.value })
									}
									id="inquiry"
									name="inquiry"
									placeholder="Your inquiry type.."
								/>
								<div style={{ marginTop: "-10px" }}>
									{validation_inquiry(form_values.inquiry).msg}
								</div>{" "}
							</div>
							<br />

							<div>
								<label for="comment">
									Message<span class="contactrequired">*</span>
								</label>
								<br />
								<input
									type="text"
									className={form_values.comment === 'not_selected' ? 'cmt-inp' : validation_comment(form_values.comment).class }
									onBlur={(e) =>
										setForm_values({ ...form_values, comment: e.target.value })
									}
									onChange={(e) =>
										setForm_values({ ...form_values, comment: e.target.value })
									}
									id="comment"
									name="comment"
									placeholder="Your Message"
									required
								/>
								<div style={{ marginTop: "-10px" }}>
									{validation_comment(form_values.comment).msg}
								</div>{" "}
							</div>
							<br />

							<div class="check">
								<input
									type="checkbox"
									name="checkbox-1"
									id="checkbox-1"
									class="check-box"
									defaultChecked
								/>
								<label
									for="checkbox-1"
									onChange={(e) =>
										setForm_values({
											...form_values,
											"checkbox-1": e.target.value,
										})
									}
									class="formchecktext"
								>
									Click here to receive{" "}
								</label>
								<span class="formchecktext">
									{" "}
									industry news and updates from Nexigo
								</span>
							</div>

							<div class="sub mt-2">
								<input
									class="submit"
									type="submit"
									value={success_popup ? "Sending..." : "Submit"}
									onClick={sendEmail}
									disabled={success_popup}
								/>
							</div>
						</form>
					</div>
					<hr className="contactformruler" />
				</>
			)}
		</div>
	);
}
