import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/homepage/Home.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useInView } from "react-intersection-observer";
import { motion, useMotionValue } from "framer-motion";
import "react-lazy-load-image-component/src/effects/blur.css";

import {
  Edit_Single_Text,
  Edit_Image,
} from "../../functions/ReuseableFunctions";

export default function VigorSection(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1100px)",
  });

  const [ref1, inView1] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref2, inView2] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref3, inView3] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref4, inView4] = useInView({
    threshold: 0,
    triggerOnce: false,
  });
  const [ref5, inView5] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref6, inView6] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref7, inView7] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref8, inView8] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref9, inView9] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref10, inView10] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const ani_mate1 = {
    visible: { opacity: 1 },
    hidden: {
      opacity: 0,
      scale: 1,
      x: -150,
    },
  };

  const ani_mate2 = {
    visible: { opacity: 1 },
    hidden: {
      opacity: 0,
      scale: 1,
      y: 50,
    },
  };

  const ani_mate3 = {
    visible: { opacity: 1 },
    hidden: {
      opacity: 0,
      scale: 1,
      x: -150,
    },
  };

  const ani_mate4 = {
    visible: { opacity: 1 },
    hidden: {
      opacity: 0,
      y: 100,
    },
  };

  const [refimg, inViewimg] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [refcp, inViewcp] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  const [ref, inView] = useInView({
    threshold: 0,
    triggerOnce: false,
  });

  return (
    <>
      <div>
        <div class="container vigorCover">
          <div class="vigortitlesection mt-5">
            <motion.div
              animate={!isDesktopOrLaptop || inView ? "visible" : "hidden"}
              variants={ani_mate2}
              transition={{ duration: 0.6 }}
              ref={ref}
              class="vigortitle pt-2 mb-3"
            >
              {Edit_Single_Text(
                props.VigorSection.title,
                "VigorSection.title",
                props.id,
                props.activate
              )}
            </motion.div>

            <motion.div
              animate={!isDesktopOrLaptop || inView ? "visible" : "hidden"}
              variants={ani_mate2}
              transition={{ duration: 0.6, delay: 0.2 }}
              ref={ref}
              class="vigortitledesc mb-3"
            >
              {Edit_Single_Text(
                props.VigorSection.titledesc,
                "VigorSection.titledesc",
                props.id,
                props.activate
              )}
            </motion.div>

            <motion.div
              animate={!isDesktopOrLaptop || inView ? "visible" : "hidden"}
              variants={ani_mate2}
              transition={{ duration: 0.6, delay: 0.4 }}
              ref={ref}
              class="vigortitledesc2"
            >
              {Edit_Single_Text(
                props.VigorSection.titledesc2,
                "VigorSection.titledesc2",
                props.id,
                props.activate
              )}
            </motion.div>
          </div>

          <div class="row vigorcontent">
            <div class="col-lg-6 col-md-12 col-sm-12 pr-lg-5 mt-3 ">
              {Edit_Image(
                props.VigorSection.vigorImg,
                "VigorSection.vigorImg",
                props.id,
                props.activate,
                "img-fluid vigorImg mb-4"
              )}
            </div>

            <div class="col-lg-6 col-md-12 col-sm-12">
              <div class="vigorcontentpara mb-3">
                {Edit_Single_Text(
                  props.VigorSection.contentpara,
                  "VigorSection.contentpara",
                  props.id,
                  props.activate
                )}
              </div>

              <div class="viglistitems pt-2">
                <ul class="vigunorderedlist">
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView1 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6 }}
                    ref={ref1}
                    class="viglistitem viglistitem1"
                  >
                    {Edit_Single_Text(
                      props.VigorSection.listitem1,
                      "VigorSection.listitem1",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView2 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.1 }}
                    ref={ref2}
                    class="viglistitem viglistitem2"
                  >
                    {Edit_Single_Text(
                      props.VigorSection.listitem2,
                      "VigorSection.listitem2",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  {/* <motion.li
                    animate={
                      !isDesktopOrLaptop || inView3 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.15 }}
                    ref={ref3}
                    class="viglistitem viglistitem3"
                  >
                    {Edit_Single_Text(
                      props.VigorSection.listitem3,
                      "VigorSection.listitem3",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView4 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.2 }}
                    ref={ref4}
                    class="viglistitem viglistitem4"
                  >
                    {Edit_Single_Text(
                      props.VigorSection.listitem4,
                      "VigorSection.listitem4",
                      props.id,
                      props.activate
                    )}
                  </motion.li> */}
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView5 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.25 }}
                    ref={ref5}
                    class="viglistitem viglistitem5"
                  >
                    {Edit_Single_Text(
                      props.VigorSection.listitem5,
                      "VigorSection.listitem5",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView6 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.3 }}
                    ref={ref6}
                    class="viglistitem viglistitem6"
                  >
                    {Edit_Single_Text(
                      props.VigorSection.listitem6,
                      "VigorSection.listitem6",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView7 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.35 }}
                    ref={ref7}
                    class="viglistitem viglistitem7"
                  >
                    {Edit_Single_Text(
                      props.VigorSection.listitem7,
                      "VigorSection.listitem7",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView8 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.4 }}
                    ref={ref8}
                    class="viglistitem viglistitem8"
                  >
                    {Edit_Single_Text(
                      props.VigorSection.listitem8,
                      "VigorSection.listitem8",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  <motion.li
                    animate={
                      !isDesktopOrLaptop || inView9 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.45 }}
                    ref={ref9}
                    class="viglistitem viglistitem9"
                  >
                    {Edit_Single_Text(
                      props.VigorSection.listitem9,
                      "VigorSection.listitem9",
                      props.id,
                      props.activate
                    )}
                  </motion.li>
                  {/* <motion.li
                    animate={
                      !isDesktopOrLaptop || inView10 ? "visible" : "hidden"
                    }
                    variants={ani_mate1}
                    transition={{ duration: 0.6, delay: 0.5 }}
                    ref={ref10}
                    class="viglistitem viglistitem10"
                  >
                    {Edit_Single_Text(
                      props.VigorSection.listitem10,
                      "VigorSection.listitem10",
                      props.id,
                      props.activate
                    )}
                  </motion.li> */}
                </ul>
                {/* <div>
                  <input
                    class="vigorlearnmore"
                    type="submit"
                    value="LEARN MORE"
                  />
                </div> */}
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <hr className="vigorruler mt-4"></hr>
      </div>
    </>
  );
}
