import React, { useState, useEffect, useRef } from "react";
import { Link, Prompt, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbarbigscreen from "../components/navbar/navbar_bigscreen";
import Navbarsmallscreen from "../components/navbar/navbar_smallscreen";
import "react-lazy-load-image-component/src/effects/blur.css";
import { ToastContainer, toast } from "react-toastify";
import { useMediaQuery } from "react-responsive";

import {
	isAdmin,
	isAuthenticated,
	login,
	logout,
	emaillogin,
	debounce,
} from "../functions/ReuseableFunctions";

const Menu = (props) => {
	const [isadmin, setAdmin] = useState("");
	const isBigScreen = useMediaQuery({ query: "(min-width: 1350px)" });
	const navscreen = useMediaQuery({ query: "(min-width: 1100px)" });
	const [prevScrollPos, setPrevScrollPos] = useState(0);
	const [visible, setVisible] = useState(true);
	const navbarStyles = { transition: "top 0.2s" };
	const [signin, setsignin] = useState(false);
	const [userdata, setUserdata] = useState("");


	const handleScroll = debounce(() => {
		const currentScrollPos = window.pageYOffset;
		setVisible(
			(prevScrollPos > currentScrollPos &&
				prevScrollPos - currentScrollPos > 50) ||
				currentScrollPos < 150
		);

		setPrevScrollPos(currentScrollPos);
	}, 100);




	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [prevScrollPos, visible, handleScroll]);

	return (
		<>
			<div
				className="fixed-top"
				style={{
					...navbarStyles,
				}}
			>
                {navscreen &&
				<Navbarbigscreen load={props.load}/>

}

{!navscreen &&
				<Navbarsmallscreen  load={props.load}/>

}
			</div>
            <div style={{ marginTop: navscreen ? "70px" : "45px" }}></div>

		</>
	);
};

export default withRouter(Menu);
