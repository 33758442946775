import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { useInView } from "react-intersection-observer";
import { motion, useMotionValue } from "framer-motion";
import "../../assets/css/ContactUspage/ContactUs.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import logo from "../../assets/image/Nexigo.png";
import {
	Edit_Single_Text,
	Edit_Image,
} from "../../functions/ReuseableFunctions";
import { MdLocationOn } from 'react-icons/md';

export default function LocationSection(props) {
	const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

	const isDesktopOrLaptop = useMediaQuery({
		query: "(min-width: 1100px)",
	});

	const [ref, inView] = useInView({
		threshold: 0,
		triggerOnce: false,
	});

	const ani_mate = {
		visible: { opacity: 1 },
		hidden: {
			opacity: 0,
			scale: 1,
			y: 100,
		},
	};

	const settings = {
		infinite: true,
		speed: 200,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 2000,
		fade: true,
		pauseOnHover: false,
	};

	return (
		<div class="text-center">
			<div class="h1 font-weight-bolder d-flex justify-content-center text-center mt-5 mt-lg-0">
                <MdLocationOn size='44px' className='mt-1 mr-lg-2'/>
				{Edit_Single_Text(
					props.LocationSection.title,
					"LocationSection.title",
					props.id,
					props.activate
				)}
			</div>
			<div className="d-flex flex-sm-row flex-column  justify-content-evenly ">
				<div className="d-flex mt-lg-2 flex-column ">
					<div className="h2  mt-lg-5 mt-4">
						{Edit_Single_Text(
							props.LocationSection.country1,
							"LocationSection.country1",
							props.id,
							props.activate
						)}
					</div>

					<div className="h6 font-weight-bolder mt-4">
						{Edit_Single_Text(
							props.LocationSection.address1,
							"LocationSection.address1",
							props.id,
							props.activate
						)}
					</div>

					<div className="d-flex h6 mt-3 justify-content-center ">
						<span className="font-weight-bolder ">
							Ph:<span>&nbsp;&nbsp;</span>
						</span>
						{Edit_Single_Text(
							props.LocationSection.ph1,
							"LocationSection.ph1",
							props.id,
							props.activate
						)}
					</div>

					<div className="h6 font-weight-bold">
						{Edit_Single_Text(
							props.LocationSection.mail1,
							"LocationSection.mail1",
							props.id,
							props.activate
						)}
					</div>
				</div>

				<div className="d-flex mt-lg-2 flex-column">
                <div className="h2  mt-lg-5 mt-4">
						{Edit_Single_Text(
							props.LocationSection.country2,
							"LocationSection.country2",
							props.id,
							props.activate
						)}
					</div>

					<div className="h6 font-weight-bolder mt-4">
						{Edit_Single_Text(
							props.LocationSection.address2,
							"LocationSection.address2",
							props.id,
							props.activate
						)}
					</div>

					<div className="d-flex h6 mt-3 justify-content-center">
						<span className="font-weight-bolder">
							Ph:<span>&nbsp;&nbsp;</span>
						</span>
						{Edit_Single_Text(
							props.LocationSection.ph2,
							"LocationSection.ph2",
							props.id,
							props.activate
						)}
					</div>

					<div className="h6 font-weight-bold">
						{Edit_Single_Text(
							props.LocationSection.mail2,
							"LocationSection.mail2",
							props.id,
							props.activate
						)}
					</div>
                </div>
			</div>
		</div>
	);
}
