import { useForm } from "react-hook-form";
import React, { useState,useEffect } from "react";
import validator from "validator";
import { emaillogin } from "../../functions/ReuseableFunctions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { motion, useMotionValue } from "framer-motion";
import VisibilitySensor from "react-visibility-sensor";
import axios from "axios";
import contact from "../../assets/image/contact.svg";
import { isAuthenticated, isAdmin } from "../../functions/ReuseableFunctions";
import { useMediaQuery } from "react-responsive";
import { db } from "../../core/firebase/firebase";
import { AnimatePresence } from "framer-motion";
import Edit from "../../core/Edit_Call";
import LocationSection from "./LocationSection"
const ContactForm = () => {
	const { register, errors, handleSubmit, reset } = useForm();
	const [success_popup, SetSuccess_popup] = useState(false);



    const [database_val, setdatabase_val] = useState([]);
    const [admin, setAdmin] = useState(false);
    const [edit_value, setEdit_value] = useState([]);
    const [Edit_Activated, setEdit_Activated] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1200px)" });
  
    const fetch_database_val = async () => {
      db.collection("contact")
        .doc("KLjn3Q0I1vzt9uTbWBuu")
        .get()
        .then((response) => {
          const database_value = {
            id: response.id,
            ...response.data(),
          };
          console.log(database_value);
          setdatabase_val(database_value);
        })
        .catch((error) => {
          console.log(error);
        });
      const auth = await isAuthenticated();
      if (auth) {
        console.log(auth.id);
        setAdmin(auth);
      }
    };
  
    const activate = (name) => {
      console.log(name);
      setEdit_Activated(true);
      setEdit_value(name);
    };
  
    const deactivate = () => {
      setEdit_Activated(false);
  
      setTimeout(() => {
        fetch_database_val();
      }, 2000);
    };
  
    const acceptedValue = (val, keyone) => {
      var key = keyone.split(".");
      setdatabase_val({
        ...database_val,
        [key[0]]: { ...database_val[key[0]], [key[1]]: val },
      });
    };
  
    const Add_array_Value = (val, keyone) => {
      console.log(val);
      var key = keyone.split(".");
      setdatabase_val({
        ...database_val,
        [key[0]]: {
          ...database_val[key[0]],
          [key[1]]: [...database_val[key[0]][key[1]], val],
        },
      });
    };
  
    const Remove_array_Value = (val, keyone) => {
      var st = keyone;
      var key = keyone.split(".");
      setdatabase_val({
        ...database_val,
        [key[0]]: {
          ...database_val[key[0]],
          [key[1]]: database_val[key[0]][key[1]].filter((item) => item !== val),
        },
      });
    };
  
    useEffect(() => {
      setTimeout(() => {
        isAdmin().then((val) => {
          setAdmin(val);
        });
      }, 1000);
      fetch_database_val();
      window.scrollTo({
          top: 0,
          left: 0,
      });
    }, []);
  
    const reload = () => {
      setTimeout(() => {
        isAdmin().then(function (val) {
          setAdmin(val);
        });
      }, 1000);
    };

    

	const [values, setValues] = useState({
		name: "",
		email: "",
		subject: "",
		message: "",
		success: false,
	});

	const { name, email, subject, message, error, success } = values;

	const handleChange = (name) => (event) => {
		setValues({ ...values, error: false, [name]: event.target.value });
	};

	const toastifySuccess = () => {
		toast.success("Form sent!", {
			position: "bottom-right",
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback success",
			toastId: "notifyToast",
		});
	};

	const toastifyfail = () => {
		toast.error("Please enter vaild  details", {
			position: "bottom-right",
			autoClose: 4000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback success",
			toastId: "notifyToast",
		});
	};

	const toastifyemail = () => {
		toast.warn("Please enter vaild mail-id", {
			position: "bottom-right",
			autoClose: 4000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			className: "submit-feedback success",
			toastId: "notifyToast",
		});
	};

    function resend(){
        setValues({
            name: "",
            email: "",
            subject: "",
            message: "",
            success: false,
        })
        SetSuccess_popup(false)
    }

	function Success_popup() {
		return (
			<div className="d-inline-flex">
				<motion.div
					initial={{ opacity: 0, y: 100 }}
					animate={{ opacity: 1, y: 0 }}
					exit={{ scale: 0.65, opacity: 0 }}
					transition={{ duration: 1 }}
					className=" d-flex justify-content-center container"
				>
					{values.success ? (
						<div className="col-auto text-center m-3 pt-3 ">
							<div className="h2">Message Received !</div>
							<div >
                            <div className="pr-lg-5 pl-lg-5 pb-4 pt-4">
											<img
												width="30%"
												src="https://image.flaticon.com/icons/png/512/3587/3587986.png"
											/>
										</div>

						
                                <div className="h4 mt-3">
                               { !isAuthenticated() ? <> Check your mail for a single click login link from nexigo !! </> : <>.........</>}{" "}
								</div>
								<div className="h5 mt-3">
									A copy of your request has been sent to your inbox.
								</div>

                                <div onClick={()=>resend()} className="mt-lg-4 mt-2 mb-5 btn-nexi">
									Send again
								</div>
							</div>
						</div>
					) : (
						<div className="col-auto text-center m-3 pt-3">
							<div className="h3">Sending...!!</div>
							<div className="p-lg-4 pt-5 pb-4 ">
								<img
									width="28%"
									src="https://upload.wikimedia.org/wikipedia/commons/0/0c/Message_%28Send%29.png"
								/>{" "}
								<div className="h4 mt-5 mb-5">
                               { !isAuthenticated() ? <> Check your mail for a single click login link from nexigo !! </> : <>.......</>}{" "}
								</div>
								<div className="fh4 mt-3"></div>
							</div>
						</div>
					)}
				</motion.div>
			</div>
		);
	}

	function sendEmail(e) {
		e.preventDefault();

		if ((values.name && values.email && values.message != "") || null) {
			if (validator.isEmail(email)) {
					console.log(email);
					console.log('axios');
                SetSuccess_popup(true);
                if(!isAuthenticated()){
                emaillogin(email)}
				axios({
					method: "POST",
					url: " https://us-central1-nexigo-8be1d.cloudfunctions.net/app/api/sendmail",
					data: values,
                    crossorigin:true
				}).then((response) => {
					console.log(response.data);
                
					if (response.data === "success!") {
                        setTimeout(() => {
                            setValues({
                                name: "",
                                email: "",
                                subject: "",
                                message: "",
                                success: true,
                            });  
                        }, 2000);
						
					} else if (response.data === "fail!") {
						alert("Message failed to send.");
					}
				});
			} else {
				toastifyemail();
			}
		} else {
            setValues({
                name: "",
                email: "",
                subject: "",
                message: "",
                success: false,
            });
			toastifyfail();
            SetSuccess_popup(false);

		}
	}

	//   function sendEmail(e) {
	//     e.preventDefault();

	// if (values.name && values.email && values.message != ''||null )
	// {

	//   if(validator.isEmail(email)){

	//     emailjs
	//       .sendForm(
	//         "service_a7yxjxf",
	//         "template_1mn010h",
	//         e.target,
	//         "user_mepdH9uMFD7V0XahPweAt"
	//       )
	//       .then(
	//         (result) => {
	//           console.log(result.text);
	//         },
	//         (error) => {
	//           console.log(error.text);
	//         }
	//       );
	//       setValues('')
	//     toastifySuccess();}

	//     else{
	//       toastifyemail();
	//     }

	//     }

	//     else{
	//       toastifyfail();
	//     }

	//   }

	const item6 = {
		visible: { opacity: 1, y: [-30, 0] },
		hidden: { opacity: 1 },
	};

	return (
		<>
          <div
            style={{
              backgroundImage:`url(${contact})`,
              backgroundPosition: "contain",
             backgroundSize: '100% 100%' ,
              backgroundRepeat: "no-repeat",
              backgroundAttachment:'fixed',
              width: "100vw",
              overflow: 'hidden',
            
              
            }}
          >
			{success_popup && <div className="text-center">{Success_popup()}</div>}
			{!success_popup && (
				<>
					<section
						id="services"
						style={{ padding: "0px" }}
						className=" p-lg-5 p-2  section"
					>
						
								<>
									<div
										
										className="row "
									>
										<div className="col-lg-5 pt-5  pt-lg-2 text-center col-md-12 ">
											<div className="h2" style={{fontWeight:'900'}}>
												CONTACT US
												{/* <hr className="hrb" /> */}
											</div>
										

									
											<div className="ContactForm p-md-5 p-lg-0 p-0 mt-5">
												<div className="contactForm ">
													<form
														id="contact-form"
														onSubmit={sendEmail}
														noValidate
													>
														<div className="row formRow ">
															<div className="col-lg-6 col-12">
																<input
																	type="text"
																	name="name"
																	value={name}
																	onChange={handleChange("name")}
																	className="form-control  formInput"
																	placeholder="Name"
																></input>
															</div>

															<div className="col-lg-6 col-12 mt-1 ">
																<input
																	type="email"
																	name="email"
																	value={email}
																	onChange={handleChange("email")}
																	className="form-control  formInput"
																	placeholder="Email address"
																></input>
															</div>
														</div>

														<div className="row mt-2 formRow">
															<div className="col">
																<input
																	type="text"
																	name="subject"
																	value={subject}
																	onChange={handleChange("subject")}
																	className="form-control  formInput"
																	placeholder="Subject"
																></input>
															</div>
														</div>

														<div className="row  mt-2 formRow">
															<div className="col">
																<textarea
																	rows={6}
																	name="message"
																	value={message}
																	onChange={handleChange("message")}
																	className="form-control  formInput"
																	placeholder="Message"
																></textarea>
															</div>
														</div>

														<div className="text-center ">
															<input
																type="submit"
																value="send"
																className="btn btn-dark btn-small btn-block mt-3 "
															/>
														</div>
													</form>
												</div>

												<ToastContainer />
											</div>
										</div>
                                        <div className='col-lg-7  col-md-12'>
                                        {database_val.LocationSection && (
          <>
            <LocationSection
              activate={activate}
              admin={admin}
              id={database_val.id}
              LocationSection={database_val.LocationSection}
            />
          </>
        )}
                                        </div>
									</div>
								</>
						
					</section>

                   
				</>
			)}
            </div>
            <AnimatePresence>
          {Edit_Activated && isDesktopOrLaptop && admin && (
            <>
              <motion.div
                className="popup-box_edit"
                initial={{ x: -500, opacity: 1 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -500, opacity: 1 }}
                transition={{ duration: 0.8 }}
              >
                <div
                  className="box_edit"
                  style={{
                    marginBottom: "100px",
                  }}
                >
                  <Edit
                    collection={"contact"}
                    edit_value={edit_value}
                    deactivate={deactivate}
                    acceptValue={acceptedValue}
                    Add_array_Value={Add_array_Value}
                    Remove_array_Value={Remove_array_Value}
                  ></Edit>
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
		</>
	);
};

export default ContactForm;
